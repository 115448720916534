import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core'
import { provideRouter } from '@angular/router'
import { routes } from './app.routes'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin'
import { withNgxsLoggerPlugin } from '@ngxs/logger-plugin'
import { provideStore } from '@ngxs/store'
import { NgxMaskConfig, provideEnvironmentNgxMask } from 'ngx-mask'
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http'
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { DatePipe, registerLocaleData } from '@angular/common'
import { authInterceptor } from './interceptors/auth.interceptor'
import { tokenInterceptor } from './interceptors/token.interceptor'
import { LayoutMetaState } from '../store/layout/layout.meta.state'
import { QuoteMetaState } from '../store/quote/quote.meta.state'
import { UserMetaState } from '../store/user/user.meta.state'
import { CartMetaState } from '../store/cart/cart.meta.state'
import { SimulationMetaState } from '../store/simulation/simulation.meta.state'
import { SmartSuggestionMetaState } from '../store/smart-suggestion/smart-suggestion.meta.state'
import { ChatMetaState } from '../store/chat/chat.meta.state'
import localePt from '@angular/common/locales/pt'
import {
  MatNativeDateModule,
  provideNativeDateAdapter,
} from '@angular/material/core'

const maskConfig: Partial<NgxMaskConfig> = {
  validation: false,
}

// Registra os dados de localização para o idioma português
registerLocaleData(localePt, 'pt')

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json')
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideStore(
      [
        LayoutMetaState,
        QuoteMetaState,
        UserMetaState,
        CartMetaState,
        SimulationMetaState,
        SmartSuggestionMetaState,
        ChatMetaState,
      ],
      withNgxsReduxDevtoolsPlugin(),
      withNgxsLoggerPlugin(),
    ),
    provideEnvironmentNgxMask(maskConfig),
    provideHttpClient(withInterceptors([authInterceptor, tokenInterceptor])),
    provideTranslateService({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    DatePipe,
    provideNativeDateAdapter(),
  ],
}
