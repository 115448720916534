import { IChat } from './chat.meta.model'

export class ResetChatStateAction {
  public static readonly type = '[ChatMeta] Reset to Initial State'
  constructor() {}
}

export class SetUserChatsAction {
  public static readonly type = '[ChatMeta] Set User Chats'
  constructor(public payload: IChat[]) {}
}

export class UpdateUserChatAction {
  public static readonly type = '[ChatMeta] Update User Chat'
  constructor(public payload: IChat) {}
}
