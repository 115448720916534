import { TermsComponent } from '../../../../../pages/terms/terms.component'
import { Store } from '@ngxs/store'
import { Utils } from 'src/helpers/utils.helper'
import {
  Component,
  ElementRef,
  HostListener,
  inject,
  OnInit,
} from '@angular/core'
import { ResetUserStateAction } from 'src/store/user/user.meta.actions'
import { UserMetaState } from 'src/store/user/user.meta.state'
import { MatDialog } from '@angular/material/dialog'
import { environment } from 'src/environments/environment'
import { AsyncPipe } from '@angular/common'
import { FeatherIconsComponent } from '../../../feather-icons/feather-icons.component'
import { TranslateModule } from '@ngx-translate/core'
import { UserProfileComponent } from '../../../../../pages/user-profile/user-profile.component'
import { CompanyProfileComponent } from '../../../../../pages/company-profile/company-profile.component'
import { MySubordinatesComponent } from '../../../../../pages/my-subordinates/my-subordinates.component'

@Component({
  standalone: true,
  imports: [AsyncPipe, FeatherIconsComponent, TranslateModule, TermsComponent],
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  private utils = inject(Utils)
  private store = inject(Store)
  private dialog = inject(MatDialog)
  private elementRef = inject(ElementRef)

  dropdowns: boolean = false
  environment = environment
  user$ = this.store.select(UserMetaState.getUser)

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (
      this.dropdowns &&
      !this.elementRef.nativeElement.contains(event.target)
    ) {
      this.togg()
    }
  }

  ngOnInit() {}

  togg() {
    this.dropdowns = !this.dropdowns
  }

  logout() {
    this.store.dispatch(new ResetUserStateAction())
    this.utils.navigateTo('/auth/login')
  }

  goToUserDetailsPage() {
    const user = this.store.selectSnapshot(UserMetaState.getUser)
    this.dialog.open(UserProfileComponent, {
      data: user.id,
      panelClass: 'main-modal',
      width: '1200px',
      maxWidth: '95vw',
    })
  }

  goToCompanyProfilePage() {
    const user = this.store.selectSnapshot(UserMetaState.getUser)
    this.dialog.open(CompanyProfileComponent, {
      data: user.id,
      panelClass: 'main-modal',
      width: '1200px',
      maxWidth: '95vw',
    })
  }

  goToMySubordinates() {
    const user = this.store.selectSnapshot(UserMetaState.getUser)
    this.dialog.open(MySubordinatesComponent, {
      data: user.id,
      panelClass: 'main-modal',
      width: '1300px',
      maxWidth: '90vw',
    })
  }

  goToTerms() {
    const user = this.store.selectSnapshot(UserMetaState.getUser)
    this.dialog.open(TermsComponent, {
      data: user,
      panelClass: 'main-modal',
      width: '1260px',
    })
  }
}
