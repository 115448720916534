<div>
  <ul class="d-flex justify-content-between">
    @for (forecast of weather; let i = $index; track i) {
      @if (i == 0) {
        <li class="d-flex align-items-center actual-day me-2">
          <img class="weather-icon-main" [src]="forecast.icon" alt="" />
          <div class="ms-2 actual-day-details">
            <div class="temperature">
              <span>{{ forecast.temp }}º</span>
            </div>
            <p class="mb-0 day">{{ forecast.name }} - {{ forecast.state }}</p>
            <p class="mb-0">
              {{ forecast.day | date: 'EEEE' | titlecase | translate }} -
              {{ forecast.day | date: 'dd/MM' }}
            </p>
          </div>
        </li>
      } @else {
        <li class="d-flex other-day">
          <div class="d-flex flex-column w-100 justify-content-between">
            <div class="other-day-details">
              <p class="mb-0 text-center">
                {{
                  (forecast.day | date: 'EEEE' | titlecase | translate).slice(
                    0,
                    3
                  )
                }}
              </p>
              <p class="mb-0 text-center day">
                {{ forecast.day | date: 'dd/MM' }}
              </p>
            </div>
            <img class="weather-icon" [src]="forecast.icon" alt="" />
            <div class="temperature text-center">{{ forecast.temp }}º</div>
          </div>
        </li>
      }
    }
  </ul>
</div>
