import {
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core'
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router'
import { Store } from '@ngxs/store'
import { ToggleSidebarAction } from 'src/store/layout/layout.meta.actions'
import { LayoutService } from '../../services/layout.service'
import { Menu, NavService } from '../../services/nav.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FeatherIconsComponent } from '../feather-icons/feather-icons.component'
import { CommonModule, NgStyle } from '@angular/common'
import { CustomIconsComponent } from '../custom-icons/custom-icons.component'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  standalone: true,
  imports: [
    FeatherIconsComponent,
    RouterLink,
    NgStyle,
    CommonModule,
    CustomIconsComponent,
    TranslateModule,
    RouterLinkActive,
  ],
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  private router = inject(Router)
  public navServices = inject(NavService)
  public layout = inject(LayoutService)
  private store = inject(Store)
  private elementRef = inject(ElementRef)
  private destroyRef = inject(DestroyRef)

  public menuItems: Menu[]
  public url: any
  public showControl: boolean = true

  // For Horizontal Menu
  public margin: any = 0
  public width: any = window.innerWidth
  public leftArrowNone: boolean = true
  public rightArrowNone: boolean = false

  constructor() {
    this.navServices.items
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((menuItems) => {
        this.menuItems = menuItems
        this.router.events
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((event) => {
            if (event instanceof NavigationEnd) {
              menuItems.filter((items) => {
                if (items.path === event.url) {
                  this.setNavActive(items)
                }
                if (!items.children) {
                  return false
                }
                items.children.filter((subItems) => {
                  if (subItems.path === event.url) {
                    this.setNavActive(subItems)
                  }
                  if (!subItems.children) {
                    return false
                  }
                  subItems.children.filter((subSubItems) => {
                    if (subSubItems.path === event.url) {
                      this.setNavActive(subSubItems)
                    }
                  })
                })
              })
            }
          })
      })
  }

  ngOnInit() {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const target = event.target as HTMLElement
    const isClickedInside = this.elementRef.nativeElement.contains(event.target)
    const button = document.getElementById('icon-toggle-sidebar')
    const isMobile = window.innerWidth < 992
    if (
      !isClickedInside &&
      !button.contains(target) &&
      !this.navServices.collapseSidebar &&
      isMobile
    ) {
      this.sidebarToggle()
    }
  }

  sidebarToggle() {
    this.store.dispatch(new ToggleSidebarAction())
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  // Active Nave state
  setNavActive(item) {
    /*     this.menuItems.filter((menuItem) => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    }); */
  }

  // Click Toggle menu
  toggletNavActive(item) {
    // INFO: Client requested to let sub menu items to keep opened
    /* if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) {
          return false;
        }
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    } */
    item.active = !item.active
    this.menuItems.forEach((a) => {
      if (a.active && a.path !== item.path) {
        a.active = false
      }
    })
  }

  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0
      this.leftArrowNone = true
      this.rightArrowNone = false
    } else {
      this.margin += this.width
      this.rightArrowNone = false
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464
      this.leftArrowNone = false
      this.rightArrowNone = true
    } else {
      this.margin += -this.width
      this.leftArrowNone = false
    }
  }
}
