import { Component, inject } from '@angular/core'
import { Utils } from 'src/helpers/utils.helper'
import { FeatherIconsComponent } from '../../../feather-icons/feather-icons.component'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  standalone: true,
  imports: [FeatherIconsComponent, TranslateModule],
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  private utils = inject(Utils)

  openNotification: boolean = false

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification
  }

  goToNotifications() {
    this.utils.navigateTo('/notifications/list')
  }
}
