import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http'
import { catchError } from 'rxjs/operators'
import { throwError } from 'rxjs'
import { ICurrentUser } from '../../store/user/user.meta.model'
import {
  ResetUserStateAction,
  SetCurrentUserAction,
} from '../../store/user/user.meta.actions'
import Swal from 'sweetalert2'
import { AuthService } from '../shared/services/auth.service'
import { inject } from '@angular/core'
import { Store } from '@ngxs/store'
import { Utils } from '../../helpers/utils.helper'
import { NavService } from '../shared/services/nav.service'

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService)
  const store = inject(Store)
  const utils = inject(Utils)
  const navService = inject(NavService)

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (
        (error.status === 401 && error.error.message === 'Unauthorized') ||
        error.status === 403 ||
        error.error.statusCode === 401
      ) {
        const routePath = window.location.pathname
        if (routePath.includes('auth')) {
          return throwError(error)
        }
        Swal.close()
        authService.refreshToken().subscribe({
          next: (response) => {
            const payload: ICurrentUser = {
              accessToken: response.accessToken,
              ...response.user,
            }
            store.dispatch(new SetCurrentUserAction(payload))
            utils.navigateTo('/home')
            navService.updateAllowedMenuItems()
            utils.showSuccessMessage(
              'Success',
              'You have been successfully logged in.',
            )
          },
          error: (error) => {
            utils.showErrorMessage(
              'Oops...',
              error?.error?.message ||
                'Something went wrong. Please, try again.',
            )
            store.dispatch(new ResetUserStateAction())
            utils.navigateTo('auth/login')
          },
        })
      }
      return throwError(error)
    }),
  )
}
