<div class="translate_wrapper">
  <div class="more_lang">
    @for (lang of languages; track $index) {
      <div
        class="lang"
        data-value="en"
        (click)="changeLanguage(lang)"
        [class.selected]="selectedLanguage.code == lang.code"
      >
        <i class="flag-icon flag-icon-{{ lang.icon }}"></i>
        <span class="lang-txt">
          {{ lang.language }}
          @if (lang.type) {
            <span>({{ lang.type }})</span>
          }
        </span>
      </div>
    }
  </div>
</div>
