import {
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  OnInit,
} from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Select, Store } from '@ngxs/store'
import { OrderHelper } from 'src/helpers/order.helper'
import {
  RemoveItemToCartAction,
  ResetCartStateAction,
  UpdateCartItemQuantityAction,
} from 'src/store/cart/cart.meta.actions'
import { CartMetaState } from 'src/store/cart/cart.meta.state'
import {
  ResetQuoteStateAction,
  SetChargerKitCompositionAction,
  SetCheckoutKitTypeAction,
  SetQuoteDataItemAction,
  SetShippingAction,
  SetStandardKitCompositionAction,
  UpdateQuoteItemAction,
  UpdateQuoteItemQuantityAction,
} from 'src/store/quote/quote.meta.actions'
import { QuoteMetaState } from 'src/store/quote/quote.meta.state'
import { InputHelper } from '../../../../../../helpers/input.helper'
import { Utils } from '../../../../../../helpers/utils.helper'
import { CartService } from '../../../../services/cart.service'
import { FreightType } from 'src/enums/quotation.enum'
import { KitType, StoreChargers } from 'src/store/quote/quote.meta.model'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FeatherIconsComponent } from '../../../feather-icons/feather-icons.component'
import { AsyncPipe, CurrencyPipe } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  standalone: true,
  imports: [FeatherIconsComponent, AsyncPipe, TranslateModule, CurrencyPipe],
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  private modalService = inject(NgbModal)
  private store = inject(Store)
  private utils = inject(Utils)
  private inputHelper = inject(InputHelper)
  private cartService = inject(CartService)
  private elementRef = inject(ElementRef)
  private orderHelper = inject(OrderHelper)
  private destroyRef = inject(DestroyRef)

  public screenwidth: any = window.innerWidth
  public openCart: boolean

  isPrimaryPickerOpened = false
  isSecondaryPickerOpened = false

  typesMatrix = {
    PANEL: 'panels',
    INVERTER: 'inverters',
    BRACKET: 'brackets',
    Protection: 'protections',
    Connectors: 'connectors',
    CABLE: 'cables',
    ACCESSORY: 'accessories',
  }

  cartItems$ = this.store.select(CartMetaState.getItems)
  total$ = this.store.select(CartMetaState.getTotal)

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = event.target.innerWidth
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (
      this.openCart &&
      !this.elementRef.nativeElement.contains(event.target)
    ) {
      this.openCartOnClick()
    }
  }

  ngOnInit() {
    this.cartService.openCartMenu$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((open) => {
        this.openCart = open
      })
  }

  // Open Modal
  openModal(popup) {
    this.modalService.open(popup, {
      backdropClass: 'dark-modal',
      centered: true,
    })
  }

  // Open cart
  openCartOnClick() {
    this.cartService.openCartMenu(this.openCart)
  }

  removeItemFromCart(item) {
    this.store.dispatch(new RemoveItemToCartAction(item))
  }

  removeItemsFromCart() {
    const cartItems = this.store.selectSnapshot(CartMetaState.getItems)
    this.store.dispatch(new ResetCartStateAction())
    this.store.dispatch(new ResetQuoteStateAction())
    cartItems.forEach((item) => {
      this.store.dispatch(
        new UpdateQuoteItemAction({
          item: this.typesMatrix[item.type] || 'panels',
          data: item,
        }),
      )
    })
  }

  increaseQuantity(item): void {
    this.changeQuantity('increase', item)
  }

  decreaseQuantity(item): void {
    this.changeQuantity('decrease', item)
  }

  inputQuantity(event, item): void {
    this.changeQuantity(event.target.value, item)
  }

  changeQuantity(operation, item): void {
    if (operation == 'decrease') {
      item.quantity--
    } else if (operation == 'increase') {
      item.quantity++
    } else if (operation === 0) {
      item.quantity = 1
    } else {
      item.quantity = operation
    }
    this.store.dispatch(
      new UpdateQuoteItemQuantityAction({
        item: this.typesMatrix[item.type] || 'panels',
        id: item.id,
        quantity: item.quantity,
      }),
    )
    this.store.dispatch(new UpdateCartItemQuantityAction(item))

    const selecteItems = this.store.selectSnapshot(
      QuoteMetaState.getSelectedItems,
    )
    if (selecteItems.length > 0) {
      const shipping = this.store.selectSnapshot(QuoteMetaState.getShipping)
      this.setShippingCost({
        freightType: shipping?.freightType || FreightType.CIF,
      })
    }
  }

  callCheckLength(e, input) {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.target.blur()
    }
    this.inputHelper.checkLength(e, input, 99999)
  }

  goToDetails(id) {
    this.utils.navigateTo(`ecommerce/standard/${id}`)
  }

  goToCheckout(): void {
    const cartItems = this.store.selectSnapshot(CartMetaState.getItems)
    const haveItensOnCart = !!cartItems.length

    if (!haveItensOnCart) return

    const cartType = this.store.selectSnapshot(CartMetaState.getCartType)

    if (cartType === KitType.CUSTOM) {
      const parsedCartItems =
        this.orderHelper.parseCustomAndChargerCartItems(cartItems)
      this.store.dispatch(
        new SetQuoteDataItemAction({ item: 'panels', data: parsedCartItems }),
      )
      this.store.dispatch(new SetCheckoutKitTypeAction(KitType.CUSTOM))
    }

    if (cartType === KitType.CHARGER) {
      const parsedCartItems =
        this.orderHelper.parseCustomAndChargerCartItems(cartItems)
      this.store.dispatch(
        new SetChargerKitCompositionAction(parsedCartItems as StoreChargers[]),
      )
      this.store.dispatch(new SetCheckoutKitTypeAction(KitType.CHARGER))
    }

    if (cartType === KitType.STANDARD) {
      const parsedCartItems = this.orderHelper.parseStandardCartItems(cartItems)
      this.store.dispatch(new SetStandardKitCompositionAction(parsedCartItems))
      this.store.dispatch(new SetCheckoutKitTypeAction(KitType.STANDARD))
    }

    this.openCartOnClick()
    this.orderHelper.saveOrder({ skipPreSave: false })
    this.store.dispatch(new ResetQuoteStateAction())
    this.openCartOnClick()
  }

  getTotalItemsLength(cartItems: any[]): number {
    return cartItems.reduce((acc, item) => acc + item.quantity, 0)
  }

  setShippingCost({ freightType }: { freightType: FreightType }) {
    const shippingCost = this.utils.calculateShippingCost(freightType)
    this.store.dispatch(
      new SetShippingAction({
        freightType,
        shippingCost,
      }),
    )
  }
}
