<mat-dialog-content
  class="subordinates-modal"
  [class.dark-only]="mixLayout === 'dark-only'"
>
  <div class="container-fluid my-subordinate">
    <div class="row">
      <div class="col-sm-12">
        <div class="card" style="border: 0">
          <div class="card-header" style="padding: 15px">
            <h2 class="page-title p-t-0 p-b-0">
              {{ 'My Subordinates' | translate }}
            </h2>
            <svg
              (click)="onCancel()"
              mat-dialog-close="true"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.817983 0.817984C1.32009 0.315882 2.13416 0.315882 2.63626 0.817984L9.00022 7.18195L15.3642 0.817984C15.8663 0.315881 16.6804 0.315881 17.1825 0.817983C17.6846 1.32009 17.6846 2.13416 17.1825 2.63626L10.8185 9.00022L17.1825 15.3642C17.6846 15.8663 17.6846 16.6804 17.1825 17.1825C16.6804 17.6846 15.8663 17.6846 15.3642 17.1825L9.00022 10.8185L2.63626 17.1825C2.13416 17.6846 1.32009 17.6846 0.817983 17.1825C0.315881 16.6804 0.315881 15.8663 0.817983 15.3642L7.18194 9.00022L0.817983 2.63626C0.315881 2.13416 0.315881 1.32009 0.817983 0.817984Z"
                fill="#E05F57"
              />
            </svg>
          </div>
          <div class="card-body" style="padding: 15px">
            @if (users) {
              <app-table
                [tableData]="users"
                [tableHeaders]="tableHeaders"
                [tableOffset]="tableConfig.offset"
                [tableLimit]="100"
                [tableCount]="tableConfig.count"
                [externalPaging]="false"
              ></app-table>
            } @else {
              <div class="empty">
                {{ 'No subordinates to display' | translate }}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
