import {
  IColors,
  ILayoutDefault,
  ILanguages,
} from './../../../../interfaces/layout/layout.interface'

export class LayoutDB {
  static colors: IColors[] = [
    {
      id: 0,
      color: '#E05F57',
    },
    {
      id: 1,
      color: '#07AAF0',
    },
    {
      id: 2,
      color: '#189AB4',
    },
    {
      id: 3,
      color: '#05445E',
    },
    {
      id: 4,
      color: '#3A6073',
    },
    {
      id: 5,
      color: '#9BB3A6',
    },
    {
      id: 6,
      color: '#3D5B59',
    },
    {
      id: 7,
      color: '#EB3349',
    },
    {
      id: 8,
      color: '#D0104C',
    },
    {
      id: 9,
      color: '#B50CED',
    },
    {
      id: 10,
      color: '#D385ED',
    },
    {
      id: 11,
      color: '#6577F0',
    },
    {
      id: 12,
      color: '#6EF0AF',
    },
    {
      id: 13,
      color: '#00FA7D',
    },
    {
      id: 14,
      color: '#A5CC82',
    },
    {
      id: 15,
      color: '#A4BE7B',
    },
    {
      id: 16,
      color: '#1D976C',
    },
    {
      id: 17,
      color: '#FF8008',
    },
    {
      id: 18,
      color: '#F45C43',
    },
    {
      id: 19,
      color: '#E83015',
    },
    {
      id: 20,
      color: '#FC0552',
    },
  ]

  static layoutDefault: ILayoutDefault[] = [
    {
      nameCSS: '--theme-deafult',
      nameStorage: 'themeDeafult',
      value: '#E05F57',
      layoutType: 'color',
    },
    {
      nameCSS: '--theme-secondary',
      nameStorage: 'themeSecondary',
      value: 'rgba(224, 95, 87, 0.1)',
      layoutType: 'color',
    },
    {
      nameCSS: '--font-default',
      nameStorage: 'fontDefault',
      value: 'Roboto',
      layoutType: 'font',
    },
    {
      nameCSS: '--font-content',
      nameStorage: 'fontContent',
      value: 'Roboto',
      layoutType: 'font',
    },
  ]

  static languages: ILanguages[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us',
    },
    {
      language: 'Português',
      code: 'pt',
      type: 'BR',
      icon: 'br',
    },
  ]
}
