import { Routes } from '@angular/router'
import { AuthGuard } from '../../../../../guards/auth.guard'
import { RoleGuard } from '../../../../../guards/role.guard'

export const contentRoutes: Routes = [
  {
    path: 'category',
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'list',
        loadComponent: () =>
          import(
            '../../../../pages/category/category-list/category-list.component'
          ).then((m) => m.CategoryListComponent),
      },
      {
        path: 'create',
        loadComponent: () =>
          import(
            '../../../../pages/category/category-create/category-create.component'
          ).then((m) => m.CategoryCreateComponent),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import(
            '../../../../pages/category/category-edit/category-edit.component'
          ).then((m) => m.CategoryEditComponent),
      },
    ],
  },
  /*  {
    path: 'chat',
    canActivate: [AuthGuard, RoleGuard],
    loadComponent: () =>
      import('../../../../pages/chat/chat/chat.component').then((m) => m.ChatComponent),
  },*/
  {
    path: 'client',
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'list',
        loadComponent: () =>
          import(
            '../../../../pages/client/client-list/client-list.component'
          ).then((m) => m.ClientListComponent),
      },
      {
        path: 'create',
        loadComponent: () =>
          import(
            '../../../../pages/client/client-create/client-create.component'
          ).then((m) => m.ClientCreateComponent),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import(
            '../../../../pages/client/client-edit/client-edit.component'
          ).then((m) => m.ClientEditComponent),
      },
    ],
  },
  {
    path: 'coupon',
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'list',
        loadComponent: () =>
          import(
            '../../../../pages/coupon/coupon-list/coupon-list.component'
          ).then((m) => m.CouponListComponent),
      },
      {
        path: 'create',
        loadComponent: () =>
          import(
            '../../../../pages/coupon/coupon-create/coupon-create.component'
          ).then((m) => m.CouponCreateComponent),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import(
            '../../../../pages/coupon/coupon-edit/coupon-edit.component'
          ).then((m) => m.CouponEditComponent),
      },
    ],
  },
  {
    path: 'credit',
    children: [
      {
        path: 'history',
        loadComponent: () =>
          import(
            '../../../../pages/credit/credit/credit-history.component'
          ).then((m) => m.CreditHistoryComponent),
      },
    ],
  },
  {
    path: 'ecommerce',
    children: [
      {
        path: 'standard',
        loadComponent: () =>
          import(
            '../../../../pages/e-commerce/standard/product/product.component'
          ).then((m) => m.ProductComponent),
      },
      {
        path: 'standard/:id',
        loadComponent: () =>
          import(
            '../../../../pages/e-commerce/standard/product-details/product-details.component'
          ).then((m) => m.ProductDetailsComponent),
      },
      {
        path: 'chargers',
        loadComponent: () =>
          import(
            '../../../../pages/e-commerce/chargers/chargers-list/chargers-list.component'
          ).then((m) => m.ChargersListComponent),
      },
      {
        path: 'chargers/:id',
        loadComponent: () =>
          import(
            '../../../../pages/e-commerce/chargers/chargers-details/chargers-details.component'
          ).then((m) => m.ChargersDetailsComponent),
      },
    ],
  },
  {
    path: 'home',
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../../../../pages/home/home.component').then(
            (m) => m.HomeComponent,
          ),
      },
      {
        path: 'heat-map',
        loadComponent: () =>
          import('../../../../pages/home/heat-map/heat-map.component').then(
            (m) => m.HeatMapComponent,
          ),
      },
    ],
  },
  {
    path: 'kits',
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'list',
        loadComponent: () =>
          import('../../../../pages/kit/kit-list/kit-list.component').then(
            (m) => m.KitListComponent,
          ),
      },
      {
        path: 'create',
        loadComponent: () =>
          import('../../../../pages/kit/kit-create/kit-create.component').then(
            (m) => m.KitCreateComponent,
          ),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../../../pages/kit/kit-edit/kit-edit.component').then(
            (m) => m.KitEditComponent,
          ),
      },
    ],
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'list',
        loadComponent: () =>
          import(
            '../../../../pages/notifications/notifications-list/notifications-list.component'
          ).then((m) => m.NotificationsListComponent),
      },
      {
        path: 'create',
        loadComponent: () =>
          import(
            '../../../../pages/notifications/notifications-create/notifications-create.component'
          ).then((m) => m.NotificationsCreateComponent),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import(
            '../../../../pages/notifications/notifications-edit/notifications-edit.component'
          ).then((m) => m.NotificationsEditComponent),
      },
    ],
  },
  {
    path: 'offer',
    canActivate: [AuthGuard, RoleGuard],
    loadComponent: () =>
      import('../../../../pages/offer/offer.component').then(
        (m) => m.OfferComponent,
      ),
  },
  {
    path: 'products',
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'list',
        loadComponent: () =>
          import(
            '../../../../pages/products/product-list/product-list.component'
          ).then((m) => m.ProductListComponent),
      },
      {
        path: 'create',
        loadComponent: () =>
          import(
            '../../../../pages/products/product-create/product-create.component'
          ).then((m) => m.ProductCreateComponent),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import(
            '../../../../pages/products/product-edit/product-edit.component'
          ).then((m) => m.ProductEditComponent),
      },
    ],
  },
  {
    path: 'proposal',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'customization',
        loadComponent: () =>
          import(
            '../../../../pages/proposal/customization/customization.component'
          ).then((m) => m.CustomizationComponent),
      },
      {
        path: 'control',
        loadComponent: () =>
          import('../../../../pages/proposal/control/control.component').then(
            (m) => m.ControlComponent,
          ),
      },
      {
        path: 'po',
        loadComponent: () =>
          import('../../../../pages/proposal/control/control.component').then(
            (m) => m.ControlComponent,
          ),
      },
      {
        path: 'simulation',
        loadComponent: () =>
          import(
            '../../../../pages/proposal/simulation/simulation.component'
          ).then((m) => m.SimulationComponent),
      },
      {
        path: 'checkout/:id',
        loadComponent: () =>
          import('../../../../pages/proposal/checkout/checkout.component').then(
            (m) => m.CheckoutComponent,
          ),
      },
      {
        path: 'proposal-details/:id',
        loadComponent: () =>
          import(
            '../../../../pages/proposal/proposal-details/proposal-details.component'
          ).then((m) => m.ProposalDetailsComponent),
      },
      {
        path: 'customization/:id',
        loadComponent: () =>
          import(
            '../../../../pages/proposal/customization/customization.component'
          ).then((m) => m.CustomizationComponent),
      },
      {
        path: 'standard',
        loadComponent: () =>
          import('../../../../pages/proposal/standard/standard.component').then(
            (m) => m.StandardComponent,
          ),
      },
      {
        path: 'standard/:id',
        loadComponent: () =>
          import('../../../../pages/proposal/standard/standard.component').then(
            (m) => m.StandardComponent,
          ),
      },
      {
        path: 'charger',
        loadComponent: () =>
          import('../../../../pages/proposal/charger/charger.component').then(
            (m) => m.ChargerComponent,
          ),
      },
      {
        path: 'charger/:id',
        loadComponent: () =>
          import('../../../../pages/proposal/charger/charger.component').then(
            (m) => m.ChargerComponent,
          ),
      },
    ],
  },
  {
    path: 'tag',
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'list',
        loadComponent: () =>
          import('../../../../pages/tag/tag-list/tag-list.component').then(
            (m) => m.TagListComponent,
          ),
      },
      {
        path: 'create',
        loadComponent: () =>
          import('../../../../pages/tag/tag-create/tag-create.component').then(
            (m) => m.TagCreateComponent,
          ),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../../../pages/tag/tag-edit/tag-edit.component').then(
            (m) => m.TagEditComponent,
          ),
      },
    ],
  },
  {
    path: 'user',
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'list',
        loadComponent: () =>
          import('../../../../pages/user/user-list/user-list.component').then(
            (m) => m.UserListComponent,
          ),
      },
      {
        path: 'create',
        loadComponent: () =>
          import(
            '../../../../pages/user/user-create/user-create.component'
          ).then((m) => m.UserCreateComponent),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../../../pages/user/user-edit/user-edit.component').then(
            (m) => m.UserEditComponent,
          ),
      },
      {
        path: 'create/:id',
        loadComponent: () =>
          import(
            '../../../../pages/user/user-create/user-create.component'
          ).then((m) => m.UserCreateComponent),
      },
      {
        path: 'edit-subordination',
        loadComponent: () =>
          import(
            '../../../../pages/user/users-edit-subordination/users-edit-subordination.component'
          ).then((m) => m.UsersEditSubordinationComponent),
      },
    ],
  },
  {
    path: 'credit',
    children: [
      {
        path: 'history',
        loadComponent: () =>
          import(
            '../../../../pages/credit/credit/credit-history.component'
          ).then((m) => m.CreditHistoryComponent),
      },
    ],
  },
  {
    path: 'wallet',
    canActivate: [AuthGuard, RoleGuard],
    loadComponent: () =>
      import('../../../../pages/wallet/wallet.component').then(
        (m) => m.WalletComponent,
      ),
  },
]
