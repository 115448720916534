import { AppConfigService, BannerKey } from '../../services/app-config.service'
import {
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { Store } from '@ngxs/store'
import {
  SetColorsAction,
  SetLanguageAction,
} from 'src/store/layout/layout.meta.actions'
import { LayoutMetaState } from 'src/store/layout/layout.meta.state'
import { LayoutService } from '../../services/layout.service'
import { NavService } from '../../services/nav.service'
import {
  IColors,
  ILanguages,
  ILayoutDefault,
} from '../../../../interfaces/layout/layout.interface'
import { LayoutQuestions } from '../../../../questions/layout.questions'
import { LocalStorageService } from '../../services/local-storage.service'
import { RolesType } from 'src/enums/roles.enum'
import { UserMetaState } from 'src/store/user/user.meta.state'
import { IFiles } from 'src/interfaces/files/files.interface'
import { UploadPhotoComponent } from '../upload-photo/upload-photo.component'
import { Utils } from 'src/helpers/utils.helper'
import { LoaderModalComponent } from '../loader-modal/loader-modal.component'
import { MatDialog } from '@angular/material/dialog'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FeatherIconsComponent } from '../feather-icons/feather-icons.component'
import { FormComponent } from '../form/form.component'
import { NgStyle } from '@angular/common'

const allowedRoles = [
  RolesType.ADMIN,
  RolesType.BUSINESS_1,
  RolesType.BUSINESS_2,
  RolesType.BACKOFFICE,
]

@Component({
  standalone: true,
  imports: [
    FeatherIconsComponent,
    TranslateModule,
    FormComponent,
    NgStyle,
    UploadPhotoComponent,
  ],
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss'],
})
export class CustomizerComponent implements OnInit {
  private modalService = inject(NgbModal)
  public navServices = inject(NavService)
  public layoutService = inject(LayoutService)
  private store = inject(Store)
  public storage = inject(LocalStorageService)
  private translate = inject(TranslateService)
  private layoutQuestions = inject(LayoutQuestions)
  private elementRef = inject(ElementRef)
  private utils = inject(Utils)
  private appConfigService = inject(AppConfigService)
  private dialog = inject(MatDialog)
  private destroyRef = inject(DestroyRef)

  public screenwidth: any = window.innerWidth
  public customizer: string = ''
  public layoutType: string = 'ltr'
  public sidebarType: string = 'compact-wrapper'
  public sidebarSetting: string = 'default-sidebar'
  public MIXLayout: string = 'default'
  public fontType: string = 'Roboto'

  public language: boolean = false

  public primary_color: string = '#E05F57'
  public secondary_color: string = 'rgba(224, 95, 87, 0.1)'

  public colors: IColors[]
  public layoutDefault: ILayoutDefault[]
  public languages: ILanguages[]

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us',
  }

  public isPrimaryPickerOpened = false
  public languageQuestions = this.layoutQuestions.LanguageQuestions
  public fontQuestions = this.layoutQuestions.FontQuestions
  public isAllowedToChangeBanner = false
  public selectedFirstBanner: IFiles[] = []
  public selectedSecondBanner: IFiles[] = []

  @ViewChild('childFirstComponentRef', { static: false })
  firstDashboardBanner!: UploadPhotoComponent
  @ViewChild('childSecondComponentRef', { static: false })
  secondDashboardBanner!: UploadPhotoComponent

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = event.target.innerWidth
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (
      this.customizer === 'option' &&
      !this.elementRef.nativeElement.contains(event.target)
    ) {
      this.customizer = ''
    }
  }

  ngOnInit() {
    this.layoutService
      .getColors()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((colors) => {
        this.colors = colors
      })

    this.layoutService
      .getLayoutDefault()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((layoutDefault) => {
        this.layoutDefault = layoutDefault
      })

    this.layoutService
      .getLanguages()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((languages) => {
        this.languages = languages
      })

    const userRole = this.store.selectSnapshot(UserMetaState.getUserRoles)
    this.isAllowedToChangeBanner = allowedRoles.some((role) =>
      userRole.includes(role),
    )
    this.initCustomizationByLocalStorage()
  }

  initCustomizationByLocalStorage() {
    const initialFontDefault = this.storage.getItem('fontDefault')
    const initialFontContent = this.storage.getItem('fontContent')
    const initialThemeDeafult = this.storage.getItem('themeDeafult')
    const initialThemeSecondary = this.storage.getItem('themeSecondary')
    const initialMIXLayout = this.storage.getItem('mixLayout')

    const language = this.store.selectSnapshot(LayoutMetaState.getLanguage)
    if (initialThemeDeafult !== null && initialThemeSecondary !== null) {
      this.storage.SetStyleVar('--theme-deafult', initialThemeDeafult)
      this.storage.SetStyleVar('--theme-secondary', initialThemeSecondary)
      this.customizeLayoutColor(initialThemeDeafult)
    }
    if (initialMIXLayout !== null) {
      this.layoutService.config.settings.layout_version = initialMIXLayout
      this.MIXLayout = initialMIXLayout
    }
    if (initialMIXLayout === 'dark-only') {
      this.storage.SetStyleVar('--background-out-body', '#161C23')
      this.storage.SetStyleVar('--text-out-body', 'white')
    } else {
      this.storage.SetStyleVar('--background-out-body', 'white')
      this.storage.SetStyleVar('--text-out-body', 'black')
    }
    if (language) {
      this.selectedLanguage = this.languages.find(
        (lang) => lang.code === language,
      )
      this.languageQuestions[0].value = language
    }
    if (initialFontDefault === null || initialFontDefault === 'null') {
      this.storage.setItem('fontDefault', 'Roboto')
      this.storage.setItem('fontContent', 'Roboto')
      return
    }
    if (initialFontDefault !== null && initialFontContent !== null) {
      this.storage.SetStyleVar('--font-default', initialFontDefault)
      this.storage.SetStyleVar('--font-content', initialFontContent)
      this.fontQuestions[0].value = initialFontDefault
    }
  }

  // Open Modal
  openModal(popup) {
    this.modalService.open(popup, {
      backdropClass: 'dark-modal',
      centered: true,
    })
  }

  // Open customizer
  Customizer(val) {
    this.customizer = val
  }

  // Customize Mix Layout
  customizeMixLayout(val) {
    this.MIXLayout = val
    this.layoutService.config.settings.layout_version = this.MIXLayout
    this.storage.setItem('mixLayout', `${this.MIXLayout}`)
    if (val === 'dark-only') {
      this.storage.SetStyleVar('--background-out-body', '#161C23')
      this.storage.SetStyleVar('--text-out-body', 'white')
    } else {
      this.storage.SetStyleVar('--background-out-body', 'white')
      this.storage.SetStyleVar('--text-out-body', 'black')
    }
  }

  // customize colors
  customizeLayoutColor(selectedColor: string) {
    this.primary_color = selectedColor
    this.secondary_color = `rgba(${this.hexToRgb(selectedColor)}, 0.1)`
    this.applyColor()
  }

  // on change language
  onLanguageResponsesChanges(event) {
    let lang = this.languages.find(
      (lang) => lang.code === event.responses.languageType,
    )
    this.changeLanguage(lang)
  }

  // change font type
  onFontResponsesChanges(event) {
    const font = event.responses.fontType
    if (font !== null || font !== 'null') {
      this.fontType = font
      this.storage.SetStyleVar('--font-default', this.fontType)
      this.storage.SetStyleVar('--font-content', this.fontType)
      //set values in local storage
      this.storage.setItem('fontDefault', `${this.fontType}`)
      this.storage.setItem('fontContent', `${this.fontType}`)
    }
  }

  //set language
  changeLanguage(lang) {
    if (lang?.code) {
      this.translate.use(lang.code)
      this.selectedLanguage = lang
      this.store.dispatch(new SetLanguageAction(lang.code))
    }
  }

  applyColor() {
    //get customize values
    const selectedPrimaryColor = this.primary_color
    const selectedSecondaryColor = this.secondary_color

    //set css var
    this.storage.SetStyleVar('--theme-deafult', selectedPrimaryColor)
    this.storage.SetStyleVar('--theme-secondary', selectedSecondaryColor)

    //set config with new values
    this.layoutService.config.color.primary_color = selectedPrimaryColor
    this.layoutService.config.color.secondary_color = selectedSecondaryColor

    const payload = {
      primary_color: selectedPrimaryColor,
      secondary_color: selectedSecondaryColor,
    }
    this.store.dispatch(new SetColorsAction(payload))

    //set values in local storage
    this.storage.setItem('themeDeafult', `${selectedPrimaryColor}`)
    this.storage.setItem('themeSecondary', `${selectedSecondaryColor}`)
  }

  resetColor() {
    //reset default values
    this.layoutDefault.forEach((layout) => {
      this.storage.SetStyleVar(layout.nameCSS, layout.value)
      this.storage.setItem(layout.nameStorage, layout.value)
      if (layout.nameCSS === '--theme-deafult') {
        this.layoutService.config.color.primary_color = layout.value
        this.primary_color = layout.value
      }
      if (layout.nameCSS === '--theme-secondary') {
        this.layoutService.config.color.secondary_color = layout.value
        this.secondary_color = layout.value
      }
    })
    this.layoutService.config.settings.layout_version = 'default'
    const payload = {
      primary_color: this.layoutDefault[0].value,
      secondary_color: this.layoutDefault[1].value,
    }
    this.store.dispatch(new SetColorsAction(payload))
  }

  hexToRgb(hex) {
    return hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => '#' + r + r + g + g + b + b,
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16))
  }

  onUploadedFirstBannerChange(uploadedImages: IFiles[]) {
    this.selectedFirstBanner = uploadedImages
  }

  onUploadedSecondBannerChange(uploadedImages: IFiles[]) {
    this.selectedSecondBanner = uploadedImages
  }

  handleSendFirstBanner() {
    this.updateDashboardBanner(
      this.selectedFirstBanner,
      BannerKey.FIRST_DASHBOARD_BANNER,
    )
  }

  handleSendSecondBanner() {
    this.updateDashboardBanner(
      this.selectedSecondBanner,
      BannerKey.SECOND_DASHBOARD_BANNER,
    )
  }

  updateDashboardBanner(selectedBanner: IFiles[], bannerKey: BannerKey) {
    const loader = this.dialog.open(LoaderModalComponent, {
      width: '500px',
      height: '300px',
      panelClass: 'main-modal',
      disableClose: true,
      data: 'Aguarde enquanto carregando algumas informações...',
    })
    this.appConfigService
      .updateDashboardBanner(selectedBanner, bannerKey)
      .subscribe({
        next: (appConfig) => {
          loader.close()
          this.appConfigService.setAppConfig(appConfig)
          this.utils
            .showSuccessMessage('Sucesso', 'Banner atualizado com sucesso.')
            .then(() => {
              if (bannerKey === BannerKey.FIRST_DASHBOARD_BANNER) {
                this.firstDashboardBanner.removeImage(
                  this.selectedFirstBanner[0].id,
                )
                return
              }
              this.secondDashboardBanner.removeImage(
                this.selectedSecondBanner[0].id,
              )
            })
        },
        error: (err) => {
          console.log(err)
          loader.close()
          const errorMessage = this.utils.getErrorMessage(err)
          this.utils.showErrorMessage(
            'Erro ao fazer upload de arquivos',
            errorMessage,
          )
        },
      })
  }
}
