<mat-dialog-content
  class="company-modal"
  [class.dark-only]="mixLayout === 'dark-only'"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card" style="border: 0">
          <div class="card-header" style="padding: 15px">
            <h2 class="page-title p-t-0 p-b-0">
              {{ 'Company Profile' | translate }}
            </h2>
          </div>
          <div class="card-body" style="padding: 15px">
            <div class="row p-t-10">
              <app-upload-photo
                [key]="'thumbnail'"
                [isMultiple]="false"
                [dropzoneDivClass]="
                  'col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3'
                "
                [uploadedImages]="image"
                (uploadedImagesChange)="onUploadedImagesChange($event)"
              ></app-upload-photo>
            </div>
          </div>
          <div class="card-body" style="padding: 15px">
            <app-form [key]="'userProfile'" [questions]="questions"></app-form>

            <div class="main-button-width p-b-20 m-t-20 float-end d-flex">
              <button
                class="btn btn-grey"
                type="button"
                mat-dialog-close="true"
                (click)="onCancel()"
              >
                {{ 'Cancel' | translate }}
              </button>
              <button
                class="btn btn-blue m-l-10"
                type="button"
                (click)="save()"
              >
                {{ 'Save' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
