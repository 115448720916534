import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { ChatMetaStateModel, IChat } from './chat.meta.model'
import {
  ResetChatStateAction,
  SetUserChatsAction,
  UpdateUserChatAction,
} from './chat.meta.actions'

const INITIAL_STATE: ChatMetaStateModel = {
  chats: [],
}

@State<ChatMetaStateModel>({
  name: 'chatMeta',
  defaults: { ...INITIAL_STATE },
})
@Injectable()
export class ChatMetaState {
  @Selector()
  public static getState(state: ChatMetaStateModel): ChatMetaStateModel {
    return state
  }

  @Selector()
  public static getChats(state: ChatMetaStateModel): IChat[] {
    return state.chats
  }

  @Selector()
  public static getHasAnyNewMessages(state: ChatMetaStateModel): boolean {
    return state.chats.some((chat) => chat.hasNewMessages)
  }

  @Action(ResetChatStateAction)
  resetState(ctx: StateContext<ChatMetaStateModel>): void {
    ctx.setState({ ...INITIAL_STATE })
  }

  @Action(SetUserChatsAction)
  setUserChats(
    ctx: StateContext<ChatMetaStateModel>,
    action: SetUserChatsAction,
  ): void {
    ctx.patchState({
      chats: action.payload,
    })
    this.persistChats(action.payload)
  }

  @Action(UpdateUserChatAction)
  updateUserChat(
    ctx: StateContext<ChatMetaStateModel>,
    action: UpdateUserChatAction,
  ): void {
    const state = ctx.getState()
    const chatIndex = state.chats.findIndex(
      (chat) => chat.id === action.payload.id,
    )
    if (chatIndex === -1) {
      return
    }
    const chats = [...state.chats]
    chats[chatIndex] = action.payload
    ctx.patchState({
      chats,
    })
    const updatedState = ctx.getState()
    this.persistChats(updatedState.chats)
  }

  persistChats(chats: IChat[]): void {
    localStorage.setItem('userChats', JSON.stringify(chats))
  }
}
