import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core'
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router'
import * as feather from 'feather-icons'
import { fadeInAnimation } from '../../../data/router-animation/router-animation'
import { LayoutService } from '../../../services/layout.service'
import { NavService } from '../../../services/nav.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NgClass } from '@angular/common'
import { HeaderComponent } from '../../header/header.component'
import { FeatherIconsComponent } from '../../feather-icons/feather-icons.component'
import { FooterComponent } from '../../footer/footer.component'
import { SidebarComponent } from '../../sidebar/sidebar.component'

@Component({
  standalone: true,
  imports: [
    NgClass,
    HeaderComponent,
    RouterOutlet,
    FeatherIconsComponent,
    RouterLink,
    FooterComponent,
    SidebarComponent,
  ],
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation],
})
export class ContentComponent implements OnInit, AfterViewInit {
  public tapToChat: string

  constructor(
    private route: ActivatedRoute,
    public navServices: NavService,
    public layout: LayoutService,
    private cd: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {
    this.route.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        this.layout.config.settings.layout = params.layout
          ? params.layout
          : this.layout.config.settings.layout
      })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace()
    })
    this.cd.detectChanges()
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : ''
  }

  get layoutClass() {
    switch (this.layout.config.settings.layout) {
      case 'Dubai':
        return 'compact-wrapper'
      case 'London':
        return 'only-body'
      case 'Seoul':
        return 'compact-wrapper modern-type'
      case 'LosAngeles':
        return this.navServices.horizontal
          ? 'horizontal-wrapper material-type'
          : 'compact-wrapper material-type'
      case 'Paris':
        return 'compact-wrapper dark-sidebar'
      case 'Tokyo':
        return 'compact-sidebar'
      case 'Madrid':
        return 'compact-wrapper color-sidebar'
      case 'Moscow':
        return 'compact-sidebar compact-small'
      case 'NewYork':
        return 'compact-wrapper box-layout'
      case 'Singapore':
        return this.navServices.horizontal
          ? 'horizontal-wrapper enterprice-type'
          : 'compact-wrapper enterprice-type'
      case 'Rome':
        return 'compact-sidebar compact-small material-icon'
      case 'Barcelona':
        return this.navServices.horizontal
          ? 'horizontal-wrapper enterprice-type advance-layout'
          : 'compact-wrapper enterprice-type advance-layout'
    }
  }

  hoverSidebar(mouseTrigger) {
    this.navServices.mouseTrigger = mouseTrigger

    if (this.navServices.collapseSidebar && this.navServices.mouseTrigger) {
      this.navServices.hoverSidebar = true
    } else {
      this.navServices.hoverSidebar = false
    }
  }

  ngOnInit() {
    this.tapToChat = '/chat/1'
  }
}
