import { Component } from '@angular/core'
import { FeatherIconsComponent } from '../../../feather-icons/feather-icons.component'

@Component({
  standalone: true,
  imports: [FeatherIconsComponent],
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss'],
})
export class MessageBoxComponent {
  public openMessageBox: boolean = false

  toggleMessageBox() {
    this.openMessageBox = !this.openMessageBox
  }
}
