import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { Observable } from 'rxjs'
import { Socket, io } from 'socket.io-client'
import { ChatArchives, ChatCreatePayload } from '../model/chat.model'
import { environment } from '../../../environments/environment'
import { UserMetaState } from '../../../store/user/user.meta.state'
import { ChatDB } from '../data/chat/chat'

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public users: any[] = []
  public archives: any[] = []
  private socket!: Socket
  private eventSourceForAllChats!: EventSource
  private eventSourceForSingleChat!: EventSource

  baseRoute = `${environment.api}/chat`

  constructor(
    private store: Store,
    private http: HttpClient,
  ) {
    this.users = ChatDB.chatUser
    this.archives = ChatDB.chatArchives
  }

  connect(): void {
    this.socket = io(environment.api)
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect()
    }
  }

  sendMessageWithSocket(message: string, chatId: string): void {
    const token = this.store.selectSnapshot(UserMetaState.getUserToken)
    this.socket.emit('message', {
      content: message,
      chatId,
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  sendMessageWithSSE(content: string, chatId: string): Observable<any> {
    return this.http.post(`${this.baseRoute}/send/${chatId}`, { content })
  }

  joinChat(user: string): void {
    this.socket.emit('join', user)
  }

  getMessages(): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.on('message', (data: any) => {
        observer.next(data)
      })
    })
  }

  getConnectedUsersWithSocket(): Observable<string[]> {
    return new Observable<string[]>((observer) => {
      this.socket.on('users', (users: string[]) => {
        observer.next(users)
      })
    })
  }

  listChats(): Observable<any> {
    const user = this.store.selectSnapshot(UserMetaState.getUser)
    return this.http.get(`${this.baseRoute}/list/${user.id}`)
  }

  getChat(chatId: string): Observable<any> {
    return this.http.get(`${this.baseRoute}/id/${chatId}`)
  }

  getChatsUpdates(): Observable<any> {
    return this.http.get(`${this.baseRoute}/chats-updates`)
  }

  findByComposition(query: {
    participantsIds?: string
    orderId?: string
  }): Observable<any> {
    return this.http.get(`${this.baseRoute}/find-by-composition`, {
      params: query,
    })
  }

  addNewParticipants(participantsIds: string, chatId: string): Observable<any> {
    return this.http.post(`${this.baseRoute}/add-new-participants/${chatId}`, {
      participantsIds,
    })
  }

  listenToChatsWithSSE(): Observable<MessageEvent> {
    const token = this.store.selectSnapshot(UserMetaState.getUserToken)
    this.eventSourceForAllChats = new EventSourcePolyfill(
      `${this.baseRoute}/events`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return new Observable((observer) => {
      this.eventSourceForAllChats.addEventListener(
        'message',
        (event: MessageEvent) => {
          observer.next(event)
        },
      )

      this.eventSourceForAllChats.onerror = (error) => {
        observer.error(error)
      }
    })
  }

  connectToChatWithSSE(chatId: string): Observable<MessageEvent> {
    const token = this.store.selectSnapshot(UserMetaState.getUserToken)
    this.eventSourceForSingleChat = new EventSourcePolyfill(
      `${this.baseRoute}/events/${chatId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return new Observable((observer) => {
      this.eventSourceForSingleChat.addEventListener(
        'message',
        (event: MessageEvent) => {
          observer.next(event)
        },
      )

      this.eventSourceForSingleChat.onerror = (error) => {
        observer.error(error)
      }
    })
  }

  disconnectFromSingleChat() {
    this.eventSourceForSingleChat?.close()
  }

  disconnectFromChatListeners() {
    this.eventSourceForAllChats?.close()
    this.eventSourceForSingleChat?.close()
  }

  createNewChat(payload: ChatCreatePayload): Observable<any> {
    return this.http.post(`${this.baseRoute}/create`, payload)
  }

  public confirmVisualization(userID: string) {
    let user = this.users.filter((users) => {
      if (users.id == userID) {
        return users
      }
    })
  }

  public getChatArchives(): Observable<ChatArchives[]> {
    const archives = new Observable((observer) => {
      observer.next(this.archives)
      observer.complete()
    })
    return <Observable<ChatArchives[]>>archives
  }
}
