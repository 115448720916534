import { Component } from '@angular/core'

@Component({
  standalone: true,
  imports: [],
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  public show: boolean = true

  constructor() {
    setTimeout(() => {
      this.show = false
    }, 1000)
  }
}
