import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core'
import { OrderService } from 'src/app/shared/services/order.service'
import { PaymentNameType } from 'src/enums/payment.enum'
import { Utils } from 'src/helpers/utils.helper'
import { Menu, NavService } from '../../../../services/nav.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FeatherIconsComponent } from '../../../feather-icons/feather-icons.component'
import { FormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { RequestListComponent } from '../../../../../pages/proposal/control/request-list/request-list.component'

@Component({
  standalone: true,
  imports: [
    FeatherIconsComponent,
    FormsModule,
    TranslateModule,
    RequestListComponent,
  ],
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  public navServices = inject(NavService)
  private elRef = inject(ElementRef)
  private renderer = inject(Renderer2)
  private orderService = inject(OrderService)
  private utils = inject(Utils)
  private destroyRef = inject(DestroyRef)

  public menuItems: Menu[]
  public items: Menu[]

  public searchResult: boolean = false
  public searchResultEmpty: boolean = false
  public text: string

  proposals = []

  @Input() showSearch = false
  @Output() sendShowSearch = new EventEmitter<boolean>()

  ngOnInit() {
    this.navServices.items
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((menuItems) => (this.items = menuItems))
    // get clicked element in page
    document.addEventListener('click', (e) => {
      const target = e.target as HTMLElement
      const search = document.getElementById('search')
      // INFO: if clicked element is not search input, then must close search div
      // INFO: SVG means the search icon
      if (target.tagName !== 'svg' && target !== search) {
        this.proposals = []
      }
    })
    this.proposals = []
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (event.code === 'Escape' && this.showSearch) {
      this.handleClickClose()
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const parentEl = this.renderer.parentNode(this.elRef.nativeElement)
    if (!parentEl.contains(event.target) && this.showSearch) {
      this.handleClickClose()
    }
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix()
    if (!term) return (this.menuItems = [])
    let items = []
    term = term.toLowerCase()
    if (term.length > 2) {
      this.orderService.searchOrdersByQuery(term).subscribe({
        next: (res) => {
          this.proposals = this.parseProposals(res)
          console.log(this.proposals)
          this.checkSearchResultEmpty(this.proposals)
        },
        error: (err) => {
          console.log(err)
        },
      })
    } else {
      this.proposals = []
      this.searchResultEmpty = false
    }
  }

  parseProposals(proposals: any[]) {
    const parsedProposals = proposals.map((proposal) => {
      const paymentMethod = proposal?.paymentMethod || ''
      const paymentMethodName =
        paymentMethod === 'FINANCING'
          ? PaymentNameType[proposal?.invoice?.paymentOrigin]
          : paymentMethod
      const leftDays = this.utils.calculateProposalDaysRemaining(
        proposal.meta.createdAt,
      )
      const isExternalUsers = this.utils.isExternalUser(proposal.owner.roles)
      return {
        id: proposal.id,
        orderNumber: proposal.orderNumber,
        owner: proposal.owner.displayName,
        orderId: proposal.id,
        ownerRole: proposal.owner.roles[0],
        title: proposal?.title || 'Sem título',
        client: proposal?.invoiceTo?.fullName,
        clientDocument: proposal?.invoiceTo?.document,
        date: proposal.meta.createdAt,
        value: proposal.totalPrice,
        status: proposal.status,
        paymentMethod: paymentMethodName,
        type: proposal.type,
        potency: this.getProposalTotalPotency(proposal.orderKitContents),
        reference: proposal.reference,
        innerFlowStatus: proposal?.innerFlowStatus,
        parent: isExternalUsers
          ? proposal?.owner?.parent?.displayName
          : proposal.owner.displayName,
        leftDays,
      }
    })
    return parsedProposals
  }

  getProposalTotalPotency(orderKitContents: any[]): number {
    return orderKitContents.reduce((acc, item) => acc + item.totalPotency, 0)
  }

  checkSearchResultEmpty(items) {
    if (!items.length) this.searchResultEmpty = true
    else this.searchResultEmpty = false
  }

  addFix() {
    this.searchResult = true
  }

  removeFix() {
    this.searchResult = false
    this.text = ''
  }

  handleClickClose() {
    this.removeFix()
    this.sendShowSearch.emit(false)
    this.proposals = []
  }

  handleSelectedProposals(event) {
    this.proposals = []
  }
}
