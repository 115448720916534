import { NavService } from './../app/shared/services/nav.service'
import { UserMetaState } from './../store/user/user.meta.state'
import { Store } from '@ngxs/store'
import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Observable } from 'rxjs'
import { RolesType } from 'src/enums/roles.enum'
import { SetUserDeviceInfo } from 'src/store/user/user.meta.actions'
import { Device } from '@capacitor/device'

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    private navService: NavService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.store.selectSnapshot(UserMetaState.getUser) as any
    if (user.roles[0] === RolesType.TEMPORARY_ACCESS) {
      this.router.navigate(['/auth/welcome'])
    }
    let menuItems = []
    const userDeviceInfo = this.store.selectSnapshot(
      UserMetaState.getUserDeviceInfo,
    )
    const info = await Device.getInfo()
    if (!userDeviceInfo) {
      this.store.dispatch(new SetUserDeviceInfo(info))
    }
    if (
      userDeviceInfo
        ? userDeviceInfo?.platform === 'web'
        : info?.platform === 'web'
    ) {
      menuItems = this.navService.getAllowedMenuItems(user.roles)
    } else {
      menuItems = this.navService.setMenuItemsForMobileApp()
    }
    const routes = menuItems.map((item) => {
      if (item.children) {
        return item.children.map((subItem) => {
          if (subItem.path) {
            return subItem.path
          }
        })
      }
      if (item.path) {
        return item.path
      }
    })
    const allRoutes = this.getAllRoutes(routes.flat())
    const currentRoute = this.getParsedRoute(state.url)
    const isRouteAllowed = allRoutes.includes(currentRoute)
    if (!isRouteAllowed) {
      this.router.navigate(['/home'])
    }
    return isRouteAllowed
  }

  private getAllRoutes(routes: string[]) {
    const allRoutes = []
    routes.map((route) => {
      if (route) {
        allRoutes.push(route)
        if (route.includes('list')) {
          allRoutes.push(route.replace('list', 'create'))
          allRoutes.push(route.replace('list', 'edit') + '/:id')
        }
      }
    })
    return allRoutes
  }

  private getParsedRoute(route: string) {
    if (route.includes('edit')) {
      const lastRouteParam = route.split('/').pop()
      route = route.replace(lastRouteParam, ':id')
    }
    return route
  }
}
