export class ChatDB {
  // Chat User
  static chatUser = [
    {
      id: 2,
      name: 'Sales',
      status: 'status pending...',
      profile: '/assets/images/user/4.png',
      seen: 'online',
      online: true,
      typing: false,
      authenticate: 0,
      newMessages: 0,
      groupLength: 11,
      lastUsersGroup: [
        {
          userId: 0,
          userImage: '/assets/images/user/5.png',
        },
        {
          userId: 1,
          userImage: '/assets/images/user/6.png',
        },
        {
          userId: 2,
          userImage: '/assets/images/user/7.png',
        },
      ],
      lastMessage: {
        time: '11:12 AM',
        text: 'OK. Can you send me the presentation material?',
      },
    },
    {
      id: 1,
      name: 'After Sales',
      status: 'Be the change',
      profile: '/assets/images/user/a.png',
      seen: 'online',
      online: true,
      typing: false,
      authenticate: 0,
      newMessages: 2,
      groupLength: 14,
      lastUsersGroup: [
        {
          userId: 1,
          userImage: '/assets/images/user/6.png',
        },
        {
          userId: 2,
          userImage: '/assets/images/user/7.png',
        },
        {
          userId: 0,
          userImage: '/assets/images/user/5.png',
        },
      ],
      lastMessage: {
        time: '10:14 AM',
        text: 'Well I am not sure. The rest of the team.',
      },
    },
    {
      id: 0,
      name: 'Jhon Doe',
      status: 'In Meeting..',
      profile: '/assets/images/user/1.jpg',
      seen: 'online',
      online: true,
      typing: false,
      authenticate: 1,
      lastMessage: {
        status: '',
        date_time: '',
      },
    },
    {
      id: 3,
      name: 'Compliant',
      status: 'In Meeting...',
      profile: '/assets/images/user/c.png',
      seen: 'online',
      online: true,
      typing: false,
      authenticate: 0,
      newMessages: 3,
      groupLength: 14,
      lastUsersGroup: [
        {
          userId: 2,
          userImage: '/assets/images/user/7.png',
        },
        {
          userId: 0,
          userImage: 'assets/images/user/5.png',
        },
        {
          userId: 1,
          userImage: 'assets/images/user/6.png',
        },
      ],
      lastMessage: {
        time: '10:14 AM',
        text: 'I will get the product code',
      },
    },
    {
      id: 4,
      name: 'Tecnical Problems',
      status: 'In Meeting..',
      profile: 'assets/images/user/2.png',
      seen: 'online',
      online: true,
      typing: false,
      authenticate: 0,
      newMessages: 3,
      groupLength: 14,
      lastUsersGroup: [
        {
          userId: 2,
          userImage: 'assets/images/user/7.png',
        },
        {
          userId: 0,
          userImage: 'assets/images/user/5.png',
        },
        {
          userId: 1,
          userImage: 'assets/images/user/6.png',
        },
      ],
      lastMessage: {
        time: '10:14 AM',
        text: 'I will get the product code',
      },
    },
  ]

  // Message
  static chat = [
    {
      id: 1,
      message: [
        {
          sender: 11,
          time: '10:12 AM',
          text: 'Are we meeting today? Project has been already finished and I have results to show you.',
        },
        {
          sender: 11,
          time: '10:20 AM',
          text: 'Actually everything was fine. I am very excited to show this to our team.',
        },
        {
          sender: 0,
          time: '10:14 AM',
          text: 'Well I am not sure. The rest of the team is not here yet. Maybe in an hour or so?.',
        },
        {
          sender: 0,
          time: '10:14 AM',
          text: 'Well I am not sure. The rest of the team.',
        },
      ],
    },
    {
      id: 2,
      message: [
        {
          sender: 0,
          time: '11:12 AM',
          text: 'Good Morning! I would like to know about the new kit you told me about.',
        },
        {
          sender: 11,
          time: '11:15 AM',
          text: "Good Morning! Glad you're interested.",
        },
        {
          sender: 11,
          time: '11:15 AM',
          text: 'This kit is on the Black Friday sale.',
        },
        {
          sender: 0,
          time: '11:12 AM',
          text: 'OK. Can you send me the presentation material?',
        },
        {
          sender: 11,
          time: '11:15 AM',
          text: 'Sure! Here it is',
        },
        {
          sender: 11,
          time: '10:20 AM',
          text: 'Presentation BYD Kit 485B78',
        },
      ],
    },
    {
      id: 0,
      message: [],
    },
    {
      id: 3,
      message: [
        {
          sender: 11,
          time: '10:12 AM',
          text: 'Are we meeting today? Project has been already finished and I have results to show you.',
        },
        {
          sender: 0,
          time: '10:14 AM',
          text: 'Well I am not sure. The rest of the team is not here yet. Maybe in an hour or so?.',
        },
        {
          sender: 0,
          time: '10:14 AM',
          text: 'Well I am not sure. The rest of the team.',
        },
        {
          sender: 11,
          time: '10:20 AM',
          text: 'Actually everything was fine. I am very excited to show this to our team.',
        },
        {
          sender: 0,
          time: '10:14 AM',
          text: 'Well I am not sure. The rest of the team.',
        },
        {
          sender: 0,
          time: '10:14 AM',
          text: 'Well I am not sure. The rest of the team.',
        },
        {
          sender: 0,
          time: '10:14 AM',
          text: 'I will get the product code',
        },
      ],
    },
    {
      id: 4,
      message: [],
    },
  ]

  static chatArchives = [
    {
      id: 0,
      po: 'PO 45789456',
      proposal: 'PR002545',
      date: '19 Aug 2022',
      isAvailable: true,
    },
    {
      id: 1,
      po: 'PO 45789456',
      proposal: 'PR452545',
      date: '17 Aug 2022',
      isAvailable: true,
    },
    {
      id: 2,
      po: 'PO 48489456',
      proposal: 'PR078945',
      date: '15 Aug 2022',
      isAvailable: true,
    },
  ]
}
