<mat-dialog-content
  class="user-modal"
  [ngClass]="{ 'dark-only': mixLayout === 'dark-only' }"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card" style="border: 0">
          <div class="card-header" style="padding: 15px">
            <h2 class="page-title p-t-0 p-b-0">
              {{ 'User Profile' | translate }}
            </h2>
          </div>
          <div class="card-body" style="padding: 15px">
            <div class="row p-t-10">
              <app-upload-photo
                [key]="'thumbnail'"
                [isMultiple]="false"
                [dropzoneDivClass]="
                  'col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3'
                "
                [uploadedImages]="image"
                (uploadedImagesChange)="onUploadedImagesChange($event)"
                (deletedImage)="onDeleteImage($event)"
              ></app-upload-photo>
            </div>
          </div>
          <div class="card-body" style="padding: 15px">
            <app-form [key]="'userProfile'" [questions]="questions"></app-form>
            <div class="card-subtitle p-t-35 p-b-15">
              <h5>
                {{ 'Social Media' | translate }}
              </h5>
            </div>
            <div class="social-media">
              <app-form
                [key]="'socialMedia'"
                [questions]="socialMedia"
                (iconClicked)="onCloseIconClicked($event)"
                class="social-media-form"
              ></app-form>
              <div class="p-t-10"></div>
              <button
                class="btn btn-black m-l-10 float-end"
                type="button"
                (click)="addRow()"
              >
                {{ '+ Add New Social Media' | translate }}
              </button>
            </div>
            <div class="card-subtitle p-t-35 p-b-15">
              <h5>{{ 'Identification Card' | translate }}</h5>
            </div>
            <div
              class="row p-t-10"
              *ngIf="selectedIdentificationCard.length === 0"
            >
              <div class="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3">
                <ngx-dropzone
                  (change)="onSelect($event, 'IDENTIFICATION_CARD')"
                  class="custom-dropzone"
                  [multiple]="false"
                  [accept]="'.pdf'"
                >
                  <ngx-dropzone-label class="dropzone-label">
                    <img
                      src="assets/images/icons/export.png"
                      class="dropzone-icon"
                      alt="export icon"
                    />
                    <div class="row" style="position: relative">
                      <span>
                        <span class="blue-text p-b-10">{{
                          'Choose file' | translate
                        }}</span>
                        {{ 'to upload' | translate }}
                      </span>
                    </div>
                  </ngx-dropzone-label>
                </ngx-dropzone>
              </div>
            </div>
            <ng-container *ngIf="selectedIdentificationCard">
              <ng-container *ngIf="selectedIdentificationCard?.length > 0">
                <div class="row p-t-20">
                  <div class="scroll-container">
                    <ng-container
                      *ngFor="let file of selectedIdentificationCard"
                    >
                      <div class="row" style="padding-bottom: 7px">
                        <div class="col-2">
                          <span>{{
                            file.name.length > 25
                              ? file.name.slice(0, 24) + '...'
                              : file.name
                          }}</span>
                        </div>
                        <div class="col-custom">
                          <div
                            class="tag"
                            [class]="file?.attachmentType || 'OTHER'"
                          >
                            {{ 'Identification Card' | translate }}
                          </div>
                        </div>
                        <div class="col-custom">
                          <img
                            src="assets/images/icons/close.png"
                            class="close-icon float-end"
                            alt="close"
                            (click)="removeFile([file], file?.attachmentType)"
                          />
                        </div>
                        <div class="col-custom">
                          <img
                            src="assets/images/icons/download-icon.png"
                            class="download-icon float-end"
                            (click)="downloadFile(file.url)"
                            alt="download icon"
                          />
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div class="card-subtitle p-t-35 p-b-15">
              <h5>{{ 'Social Contract' | translate }}</h5>
            </div>
            <div class="row p-t-10" *ngIf="selectedSocialContract.length === 0">
              <div class="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3">
                <ngx-dropzone
                  (change)="onSelect($event, 'SOCIAL_CONTRACT')"
                  class="custom-dropzone"
                  [multiple]="false"
                  [accept]="'.pdf'"
                >
                  <ngx-dropzone-label class="dropzone-label">
                    <img
                      src="assets/images/icons/export.png"
                      class="dropzone-icon"
                      alt="export icon"
                    />
                    <div class="row" style="position: relative">
                      <span>
                        <span class="blue-text p-b-10">{{
                          'Choose file' | translate
                        }}</span>
                        {{ 'to upload' | translate }}
                      </span>
                    </div>
                  </ngx-dropzone-label>
                </ngx-dropzone>
              </div>
            </div>
            <ng-container *ngIf="selectedSocialContract">
              <ng-container *ngIf="selectedSocialContract?.length > 0">
                <div class="row p-t-20">
                  <div class="scroll-container">
                    <ng-container *ngFor="let file of selectedSocialContract">
                      <div class="row" style="padding-bottom: 7px">
                        <div class="col-2">
                          <span>{{
                            file.name.length > 25
                              ? file.name.slice(0, 24) + '...'
                              : file.name
                          }}</span>
                        </div>
                        <div class="col-custom">
                          <div
                            class="tag"
                            [class]="file?.attachmentType || 'OTHER'"
                          >
                            {{ 'Social Contract' | translate }}
                          </div>
                        </div>
                        <div class="col-custom">
                          <img
                            src="assets/images/icons/close.png"
                            class="close-icon float-end"
                            alt="close"
                            (click)="removeFile([file], file?.attachmentType)"
                          />
                        </div>
                        <div class="col-custom">
                          <img
                            src="assets/images/icons/download-icon.png"
                            class="download-icon float-end"
                            (click)="downloadFile(file.url)"
                            alt="download icon"
                          />
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div class="card-subtitle p-t-35 p-b-15">
              <h5>{{ 'CREA Number' | translate }}</h5>
            </div>
            <div class="row p-t-10" *ngIf="selectedCreaNumber.length === 0">
              <div class="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3">
                <ngx-dropzone
                  (change)="onSelect($event, 'CREA_NUMBER')"
                  class="custom-dropzone"
                  [multiple]="false"
                  [accept]="'.pdf'"
                >
                  <ngx-dropzone-label class="dropzone-label">
                    <img
                      src="assets/images/icons/export.png"
                      class="dropzone-icon"
                      alt="export icon"
                    />
                    <div class="row" style="position: relative">
                      <span>
                        <span class="blue-text p-b-10">{{
                          'Choose file' | translate
                        }}</span>
                        {{ 'to upload' | translate }}
                      </span>
                    </div>
                  </ngx-dropzone-label>
                </ngx-dropzone>
              </div>
            </div>
            <ng-container *ngIf="selectedCreaNumber">
              <ng-container *ngIf="selectedCreaNumber?.length > 0">
                <div class="row p-t-20">
                  <div class="scroll-container">
                    <ng-container *ngFor="let file of selectedCreaNumber">
                      <div class="row" style="padding-bottom: 7px">
                        <div class="col-2">
                          <span>{{
                            file.name.length > 25
                              ? file.name.slice(0, 24) + '...'
                              : file.name
                          }}</span>
                        </div>
                        <div class="col-custom">
                          <div
                            class="tag"
                            [class]="file?.attachmentType || 'OTHER'"
                          >
                            {{ 'CREA Number' | translate }}
                          </div>
                        </div>
                        <div class="col-custom">
                          <img
                            src="assets/images/icons/close.png"
                            class="close-icon float-end"
                            alt="close"
                            (click)="removeFile([file], file?.attachmentType)"
                          />
                        </div>
                        <div class="col-custom">
                          <img
                            src="assets/images/icons/download-icon.png"
                            class="download-icon float-end"
                            (click)="downloadFile(file.url)"
                            alt="download icon"
                          />
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div class="main-button-width p-b-20 m-t-20 float-end d-flex">
              <button
                class="btn btn-grey"
                type="button"
                mat-dialog-close="true"
                (click)="onCancel()"
              >
                {{ 'Cancel' | translate }}
              </button>
              <button
                class="btn btn-blue m-l-10"
                type="button"
                (click)="save()"
              >
                {{ 'Save' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
