<!-- page-wrapper Start-->
<div
  [ngClass]="layout.config.settings.layout_type"
  [class]="layout.config.settings.layout_version"
>
  <div class="page-wrapper" id="canvas-bookmark" [class]="layoutClass">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper">
      <div
        class="sidebar-wrapper"
        [attr.sidebar-layout]="layout.config.settings.sidebar_type"
        [class.close_icon]="navServices.collapseSidebar"
        [class.hover_icon]="navServices.hoverSidebar"
        (mouseenter)="hoverSidebar(true)"
        (mouseleave)="hoverSidebar(false)"
      >
        <app-sidebar></app-sidebar>
      </div>
      <div class="page-body" [class.close_icon]="navServices.collapseSidebar">
        <main [@fadeInAnimation]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
      <!-- footer start-->
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>

  <div class="tap-chat chat-button" [routerLink]="tapToChat">
    <app-feather-icons
      class="d-flex"
      [icon]="'message-circle'"
    ></app-feather-icons>
  </div>
</div>
<!-- <app-customizer></app-customizer> -->
<!-- page-wrapper End-->
