import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { InterceptorSkipHeader } from 'src/interceptors/token.interceptor'
import { environment } from '../../../environments/environment'
import { ResponseWeather, WeatherFake } from './../model/weather.model'

@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  key = environment.weatherKey
  today = new Date()
  constructor(private http: HttpClient) {}

  getNextSpecificDay(day: number): Date {
    let date = new Date()
    date.setDate(date.getDate() + day)
    return date
  }

  weather: WeatherFake[] = [
    {
      name: 'Campinas',
      state: 'SP',
      main: 'Clouds',
      description: 'clouds',
      icon: 'assets/images/weather/overcast-day.svg',
      day: this.today,
      temp: 26,
    },
    {
      name: 'Campinas',
      state: 'SP',
      main: 'Atmosfere',
      description: 'atmosfere',
      icon: 'assets/images/weather/wind.svg',
      day: this.getNextSpecificDay(1),

      temp: 27,
    },
    {
      name: 'Campinas',
      state: 'SP',
      main: 'Clouds',
      description: 'clouds',
      icon: 'assets/images/weather/overcast-day.svg',
      day: this.getNextSpecificDay(2),
      temp: 25,
    },
    {
      name: 'Campinas',
      state: 'SP',
      main: 'Clear',
      description: 'clear',
      icon: 'assets/images/weather/clear-day.svg',
      day: this.getNextSpecificDay(3),
      temp: 30,
    },
    {
      name: 'Campinas',
      state: 'SP',
      main: 'Snow',
      description: 'freezing rain',
      icon: 'assets/images/weather/thunderstorms.svg',
      day: this.getNextSpecificDay(4),

      temp: 19,
    },
    {
      name: 'Campinas',
      state: 'SP',
      main: 'Clouds',
      description: 'clouds',
      icon: 'assets/images/weather/thunderstorms-day-rain.svg',
      day: this.getNextSpecificDay(5),

      temp: 26,
    },
    {
      name: 'Campinas',
      state: 'SP',
      main: 'Rain',
      description: 'rain',
      icon: 'assets/images/weather/rain.svg',
      day: this.getNextSpecificDay(6),

      temp: 23,
    },
  ]

  getWeather(latitude, longitude): Observable<ResponseWeather> {
    return this.http.get<ResponseWeather>(
      `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${this.key}`,
      { headers: InterceptorSkipHeader },
    )
  }

  getFakeWeather() {
    return this.weather
  }
}
