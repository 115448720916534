import { Component, OnInit } from '@angular/core'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { Store } from '@ngxs/store'
import { LoaderModalComponent } from 'src/app/shared/components/loader-modal/loader-modal.component'
import { LocalStorageService } from 'src/app/shared/services/local-storage.service'
import { UserService } from 'src/app/shared/services/user.service'
import { Utils } from 'src/helpers/utils.helper'
import { ITableHeader } from 'src/interfaces/table/table.interface'
import { User } from 'src/interfaces/user/user.interface'
import { MatButtonModule } from '@angular/material/button'
import { TranslateModule } from '@ngx-translate/core'
import { TableComponent } from '../../shared/components/table/table.component'

@Component({
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, TranslateModule, TableComponent],
  selector: 'app-my-subordinates',
  templateUrl: './my-subordinates.component.html',
  styleUrls: ['./my-subordinates.component.scss'],
})
export class MySubordinatesComponent implements OnInit {
  users: User[]
  mixLayout: string

  loader: {
    width: '500px'
    height: '300px'
    panelClass: 'main-modal'
    disableClose: true
    data: 'Wait while we load some information...'
  }

  tableHeaders: ITableHeader[] = [
    { prop: 'name', name: 'Name' },
    { prop: 'role', name: 'Role' },
    { prop: 'email', name: 'Email' },
    { prop: 'document', name: 'Document' },
    { prop: 'phone', name: 'Phone' },
    { prop: 'subordinateTo', name: 'Subordinate To' },
  ]

  tableConfig = {
    offset: 1,
    count: 100,
  }

  constructor(
    private userService: UserService,
    private utils: Utils,
    private dialog: MatDialog,
    private store: Store,
    public storage: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.getUsers()
    this.mixLayout = this.storage.getItem('mixLayout')
  }

  getUsers() {
    const loader = this.dialog.open(LoaderModalComponent, {
      width: '500px',
      height: '300px',
      panelClass: 'main-modal',
      disableClose: true,
      data: this.utils.getTranslatedKey(
        'Wait while we load some information...',
      ),
    })
    const params = {
      page: this.tableConfig.offset,
      limit: this.tableConfig.count,
    }
    setTimeout(() => {
      this.userService.listMySubordinates(params).subscribe({
        next: (res) => {
          this.users = this.parseUsers(res.elements || res)
          loader.close()
        },
        error: (err) => {
          console.log(err)
          this.utils.showErrorMessage(
            'Error on list users',
            err?.error?.message,
          )
          loader.close()
        },
      })
    }, 1000)
  }

  onCancel() {
    this.dialog.closeAll()
  }

  parseUsers(users: any[]): any[] {
    console.log(users)
    return users.map((user) => {
      const parsedPhoneNumber = this.utils.parsePhoneNumber(user?.phone)
      const formattedIdentification = this.utils.formatIdentification(
        user?.document,
      )
      return {
        id: user?.id,
        name: user?.displayName,
        role: user?.roles ? this.utils.getTranslatedKey(user?.roles[0]) : '',
        email: user?.email,
        subordinateTo: user?.parent?.displayName
          ? user?.parent?.displayName
          : '',
        subordinateToId: user?.parent?.id ? user?.parent?.id : '',
        document: formattedIdentification,
        phone: parsedPhoneNumber,
      }
    })
  }
}
