import { Routes } from '@angular/router'
import { ContentComponent } from './shared/components/layout/content/content.component'
import { contentRoutes } from './shared/components/layout/content/content.routes'

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'auth/login',
    loadComponent: () =>
      import('../app/pages/authentication/login/login.component').then(
        (m) => m.LoginComponent,
      ),
  },
  {
    path: 'auth/register',
    loadComponent: () =>
      import('../app/pages/authentication/register/register.component').then(
        (m) => m.RegisterComponent,
      ),
  },
  {
    path: 'auth/forgot-password',
    loadComponent: () =>
      import(
        '../app/pages/authentication/forgot-password/forgot-password.component'
      ).then((m) => m.ForgotPasswordComponent),
  },
  {
    path: 'auth/welcome',
    loadComponent: () =>
      import('../app/pages/authentication/welcome/welcome.component').then(
        (m) => m.WelcomeComponent,
      ),
  },
  {
    path: '',
    component: ContentComponent,
    children: contentRoutes,
  },
  {
    path: '**',
    redirectTo: 'home',
  },
]
