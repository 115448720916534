import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import {
  ResetUserStateAction,
  SetCurrentUserAction,
} from 'src/store/user/user.meta.actions'
import { ICurrentUser } from 'src/store/user/user.meta.model'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user = JSON.parse(localStorage.getItem('user')) as ICurrentUser
    if (!user && !user?.isUserAuthenticated) {
      this.store.dispatch(new ResetUserStateAction())
      this.router.navigate(['/auth/login'])
    }
    this.store.dispatch(new SetCurrentUserAction(user))
    return user.isUserAuthenticated
  }
}
