import { statesChoices } from 'src/helpers/states-choice.helper'
import { IQuestion } from './../interfaces/question/question.interface'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class UserProfileQuestions {
  constructor() {}

  questions: IQuestion[] = [
    {
      formKey: 'userProfile',
      key: 'name',
      title: 'Name',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12',
    },
    {
      formKey: 'userProfile',
      key: 'fantasyName',
      title: 'Name Company',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12',
    },
    {
      formKey: 'userProfile',
      key: 'email',
      title: 'Email',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      isEmail: true,
      class: 'col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4',
    },
    {
      formKey: 'userForm',
      key: 'password',
      title: 'Password',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'textPassword',
      questionType: 'password',
      minLength: 6,
      class: 'col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4',
    },
    {
      formKey: 'userProfile',
      key: 'document',
      title: 'Document',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      mask: 'CPF_CNPJ',
      type: 'text',
      class: 'col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4',
    },
    {
      formKey: 'userProfile',
      key: 'phone',
      title: 'Cellphone',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      mask: '(00) 00000-0000',
      type: 'text',
      class: 'col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4',
    },
    {
      formKey: 'userProfile',
      key: 'country',
      title: 'Country',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-6 col-lg-3 col-xl-4',
    },
    {
      formKey: 'userProfile',
      key: 'zipCode',
      title: 'CEP',
      required: true,
      mask: '00000-000',
      pattern: /^\d{5}\-\d{3}$/,
      dropSpecialCharacters: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4',
    },
    {
      formKey: 'userProfile',
      key: 'street',
      title: 'Street',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-9 col-lg-5 col-xl-5',
    },
    {
      formKey: 'userProfile',
      key: 'number',
      title: 'Number',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      questionType: 'number',
      class: 'col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2',
    },
    {
      formKey: 'userProfile',
      key: 'complement',
      title: 'Complement',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-8 col-lg-5 col-xl-5',
    },
    {
      formKey: 'userProfile',
      key: 'neighborhood',
      title: 'Neighborhood',
      required: true,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-4 col-lg-5 col-xl-5',
    },
    {
      formKey: 'userProfile',
      key: 'city',
      title: 'City',
      required: false,
      value: '',
      isDisabled: true,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5',
    },
    {
      formKey: 'userProfile',
      key: 'state',
      title: 'State',
      required: false,
      value: '',
      isDisabled: true,
      response: {
        value: '',
      },
      type: 'select',
      choices: statesChoices,
      class: 'col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2',
    },
    {
      formKey: 'userProfile',
      key: 'stateRegistration',
      title: 'State Registration',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-5 col-lg-6 col-xl-6',
    },
    {
      formKey: 'userProfile',
      key: 'website',
      title: 'Website',
      required: false,
      value: '',
      isDisabled: false,
      response: {
        value: '',
      },
      type: 'text',
      class: 'col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6',
    },
  ]
}
