import { Component, OnInit, HostListener } from '@angular/core'
import { ViewportScroller } from '@angular/common'
import { FeatherIconsComponent } from '../feather-icons/feather-icons.component'

@Component({
  standalone: true,
  imports: [FeatherIconsComponent],
  selector: 'app-tap-to-top',
  templateUrl: './tap-to-top.component.html',
  styleUrls: ['./tap-to-top.component.scss'],
})
export class TapToTopComponent implements OnInit {
  public show: boolean = false

  constructor(private viewScroller: ViewportScroller) {}

  ngOnInit(): void {}

  // @HostListener Decorator
  @HostListener('window:scroll', [])
  onWindowScroll() {
    let number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    this.show = number > 600
  }

  tapToTop() {
    this.viewScroller.scrollToPosition([0, 0])
  }
}
