import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { LayoutService } from '../../services/layout.service'
import { NavService } from '../../services/nav.service'
import { WeatherService } from '../../services/weather.service'
import { Weather } from '../../../../interfaces/weather/weather.interface'
import { environment } from '../../../../environments/environment'
import Swiper, { Autoplay, SwiperOptions } from 'swiper'
import { BookmarkComponent } from './elements/bookmark/bookmark.component'
import { CartComponent } from './elements/cart/cart.component'
import { LanguagesComponent } from './elements/languages/languages.component'
import { MessageBoxComponent } from './elements/message-box/message-box.component'
import { MyAccountComponent } from './elements/my-account/my-account.component'
import { NotificationComponent } from './elements/notification/notification.component'
import { SearchComponent } from './elements/search/search.component'
import { WeatherComponent } from './elements/weather/weather.component'
import { TranslateModule } from '@ngx-translate/core'
import { FeatherIconsComponent } from '../feather-icons/feather-icons.component'
import { SwiperModule } from 'swiper/angular'
import { RouterLink } from '@angular/router'
import { CustomizerComponent } from '../customizer/customizer.component'

@Component({
  standalone: true,
  imports: [
    BookmarkComponent,
    CartComponent,
    LanguagesComponent,
    MessageBoxComponent,
    MyAccountComponent,
    NotificationComponent,
    SearchComponent,
    WeatherComponent,
    TranslateModule,
    FeatherIconsComponent,
    SwiperModule,
    RouterLink,
    CustomizerComponent,
  ],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public isStaging = environment.staging
  public elem: any
  public showSearch = false
  public dark: boolean =
    this.layout.config.settings.layout_version == 'dark-only'

  public weather: Weather[]

  notifications = [
    {
      notification: 'Marcus sent a new message',
      path: '/chat/1',
    },
    {
      notification: 'New kit added standard',
      path: '/ecommerce/standard',
    },
  ]

  config: SwiperOptions = {
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
    },
    direction: 'vertical',
    loop: true,
    speed: 500,
    simulateTouch: false,
  }

  constructor(
    public layout: LayoutService,
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    private weatherServices: WeatherService,
  ) {}

  ngOnInit() {
    Swiper.use([Autoplay])
    this.weather = this.weatherServices.getFakeWeather()
    this.elem = document.documentElement
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
    this.navServices.megaMenu = false
    this.navServices.levelMenu = false
  }

  layoutToggle() {
    this.dark = !this.dark
    this.layout.config.settings.layout_version = this.dark
      ? 'dark-only'
      : 'light'
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen()
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen()
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen()
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen()
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen()
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen()
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen()
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen()
      }
    }
  }

  reciveShowSearch(showSearch: boolean) {
    this.showSearch = showSearch
  }
}
