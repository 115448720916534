import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subscriber } from 'rxjs'
import { environment } from 'src/environments/environment'
import { InterceptorSkipHeader } from 'src/interceptors/token.interceptor'
import {
  IColors,
  ILanguages,
  ILayoutDefault,
} from './../../../interfaces/layout/layout.interface'
import { LayoutDB } from './../data/layout/layout'

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public observer: Subscriber<{}>
  public colors: IColors[] = []
  public layoutDefault: ILayoutDefault[] = []
  public languages: ILanguages[] = []

  public config = {
    settings: {
      layout: 'Dubai',
      layout_type: 'ltr',
      layout_version: 'light-only',
      sidebar_type: 'default-sidebar',
    },
    color: {
      primary_color: '#E05F57',
      secondary_color: 'rgba(224, 95, 87, 0.1)',
    },
  }

  constructor(private http: HttpClient) {
    if (this.config.settings.layout_type == 'rtl')
      document
        .getElementsByTagName('html')[0]
        .setAttribute('dir', this.config.settings.layout_type)

    this.colors = LayoutDB.colors
    this.layoutDefault = LayoutDB.layoutDefault
    this.languages = LayoutDB.languages
  }

  // Get User Data
  public getColors(): Observable<IColors[]> {
    const colors = new Observable((observer) => {
      observer.next(this.colors)
      observer.complete()
    })
    return <Observable<IColors[]>>colors
  }

  public getLayoutDefault(): Observable<ILayoutDefault[]> {
    const layoutDefault = new Observable((observer) => {
      observer.next(this.layoutDefault)
      observer.complete()
    })
    return <Observable<ILayoutDefault[]>>layoutDefault
  }

  public getLanguages(): Observable<ILanguages[]> {
    const languages = new Observable((observer) => {
      observer.next(this.languages)
      observer.complete()
    })
    return <Observable<ILanguages[]>>languages
  }

  getApiVersion() {
    return this.http.get(`${environment.api}/version`, {
      headers: InterceptorSkipHeader,
    })
  }
}
