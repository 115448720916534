import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core'
import { Router, RouterOutlet } from '@angular/router'
import { LoadingBarService, NgxLoadingBar } from '@ngx-loading-bar/core'
import { TranslateService } from '@ngx-translate/core'
import { Select, Store } from '@ngxs/store'
import { Observable, Subscription, firstValueFrom, timer } from 'rxjs'
import { delay, map, tap, withLatestFrom } from 'rxjs/operators'
import { SetItemsToCartAction } from 'src/store/cart/cart.meta.actions'
import { SetUserChatsAction } from 'src/store/chat/chat.meta.actions'
import { IChat } from 'src/store/chat/chat.meta.model'
import { ChatMetaState } from 'src/store/chat/chat.meta.state'
import {
  SetApiVersionAction,
  SetLanguageAction,
} from 'src/store/layout/layout.meta.actions'
import { SetUserDeviceInfo } from 'src/store/user/user.meta.actions'
import { UserMetaState } from 'src/store/user/user.meta.state'
import { cartLocalStorageKey } from 'src/store/cart/cart.meta.state'
import { Device } from '@capacitor/device'
import { AuthService } from './shared/services/auth.service'
import { LayoutService } from './shared/services/layout.service'
import { ChatService } from './shared/services/chat.service'
import { NavService } from './shared/services/nav.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { LoaderComponent } from './shared/components/loader/loader.component'
import { TapToTopComponent } from './shared/components/tap-to-top/tap-to-top.component'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgxLoadingBar, LoaderComponent, TapToTopComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private loader = inject(LoadingBarService)
  private router = inject(Router)
  private store = inject(Store)
  private authService = inject(AuthService)
  private layoutService = inject(LayoutService)
  private chatService = inject(ChatService)
  private navService = inject(NavService)
  private destroyRef = inject(DestroyRef)
  translate = inject(TranslateService)

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1]),
  )

  unreadMessagesListenerSubscription = new Subscription()

  @Select(ChatMetaState.getHasAnyNewMessages)
  hasAnyNewMessages$!: Observable<boolean>

  async ngOnInit() {
    this.translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae'])
    const savedLanguage = localStorage.getItem('language')
    if (savedLanguage) {
      this.translate.setDefaultLang(savedLanguage)
      this.translate.use(savedLanguage)
      this.store.dispatch(new SetLanguageAction(savedLanguage))
    } else {
      this.translate.setDefaultLang('pt')
      this.translate.use('pt')
      this.store.dispatch(new SetLanguageAction('pt'))
    }
    this.retrieveCartItems()
    const routeUrl = window.location.pathname
    if (routeUrl === '/') {
      this.router.navigate(['/auth/login'])
    }
    this.startWatchForApiVersionRoutine()
    this.startWatchNewUnreadMessages()

    const info = await Device.getInfo()
    this.store.dispatch(new SetUserDeviceInfo(info))
    if (info?.platform === 'web') {
      this.startWatchChatsUpdates()
    } else {
      this.navService.setMenuItemsForMobileApp()
    }
    this.authService.retrieveUser()
  }

  ngOnDestroy(): void {
    this.unreadMessagesListenerSubscription?.unsubscribe()
  }

  startWatchNewUnreadMessages(): void {
    this.unreadMessagesListenerSubscription.add(
      this.hasAnyNewMessages$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (hasAnyNewMessages) => {
            if (hasAnyNewMessages) {
              this.navService.updateMenuItem({
                title: 'Chat',
                badgeValue: '+1',
              })
            } else {
              this.navService.updateMenuItem({
                title: 'Chat',
                badgeValue: '',
              })
            }
          },
        }),
    )
  }

  startWatchChatsUpdates(): void {
    const chatsFromLocalStorage = localStorage.getItem('userChats')
    if (chatsFromLocalStorage) {
      const parsedChats = JSON.parse(chatsFromLocalStorage)
      this.store.dispatch(new SetUserChatsAction(parsedChats))
    }
    const thirtySeconds = 30000
    timer(0, thirtySeconds)
      .pipe(
        tap(() => {
          this.onGetChatsUpdates()
        }),
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe()
  }

  onGetChatsUpdates(): void {
    const routeUrl = window.location.pathname
    if (routeUrl.includes('auth')) {
      return
    }
    firstValueFrom(this.chatService.getChatsUpdates())
      .then((chats) => {
        console.log(chats)
        console.log('[AppController > ChatUpdates] Getting chats updates')
        if (!chats || chats?.length === 0) {
          return
        }
        const userChats = this.store.selectSnapshot(ChatMetaState.getChats)
        const user = this.store.selectSnapshot(UserMetaState.getUser)
        const payload: IChat[] = chats.map((chat: any) => {
          const foundChat = userChats.find((c) => c.id === chat.id)
          const lastReceivedMessageId = chat?.messages?.[0]?.id
          const lastMessageSenderEmail = chat?.messages?.[0]?.senderEmail
          return {
            id: chat.id,
            lastReceivedMessageId,
            lastSeenMessageId: foundChat?.lastSeenMessageId,
            lastMessageSenderEmail,
            hasNewMessages:
              lastMessageSenderEmail === user.email
                ? false
                : foundChat?.lastSeenMessageId !== lastReceivedMessageId,
          }
        })
        this.store.dispatch(new SetUserChatsAction(payload))
      })
      .catch((error) => {
        console.log(
          '[AppController > ChatUpdates] Error on getting chats updates',
          error,
        )
      })
  }

  startWatchForApiVersionRoutine(): void {
    const fifteenMinutes = 900000
    timer(0, fifteenMinutes)
      .pipe(
        tap(() => {
          this.getApiVersion()
        }),
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe()
  }

  getApiVersion(): void {
    firstValueFrom(this.layoutService.getApiVersion())
      .then((res: any) => {
        if (res && res.status === 'success') {
          this.store.dispatch(new SetApiVersionAction(res.version))
        } else {
          console.log('Error on get api version', res)
        }
      })
      .catch((err) => {
        console.log('Error on get api version', err)
      })
  }

  retrieveCartItems(): void {
    const cartItems = localStorage.getItem(cartLocalStorageKey)
    if (cartItems) {
      const parsedCartItems = JSON.parse(cartItems)
      this.store.dispatch(new SetItemsToCartAction(parsedCartItems))
    }
  }
}
