@if (user$ | async; as user) {
  <div (click)="togg()" class="d-flex profile-media">
    <img
      alt=""
      class="user-img"
      [src]="
        user?.image
          ? environment.api + '/' + user?.image?.downloadUrl
          : '/assets/images/dashboard/profile-1.jpg'
      "
    />
  </div>
  <ul
    [class.open]="dropdowns == true"
    class="profile-dropdown profile-sidebar-menu"
  >
    <li>
      <div class="user-name">
        <span>{{ user?.displayName }}</span>
        <p class="mb-0 font-roboto">
          {{ user?.roles?.[0] }}
        </p>
      </div>
    </li>
    <li (click)="goToUserDetailsPage()">
      <a class="d-flex align-items-center">
        <app-feather-icons [icon]="'user'"></app-feather-icons>
        <span>{{ 'User Profile' | translate }}</span></a
      >
    </li>
    <li (click)="goToCompanyProfilePage()">
      <a class="d-flex align-items-center">
        <app-feather-icons [icon]="'briefcase'"></app-feather-icons>
        <span>{{ 'Company Profile' | translate }}</span></a
      >
    </li>
    <li (click)="goToMySubordinates()">
      <a class="d-flex align-items-center">
        <app-feather-icons [icon]="'users'"></app-feather-icons>
        <span>{{ 'My Subordinates' | translate }}</span></a
      >
    </li>
    <li (click)="logout()">
      <a class="d-flex align-items-center">
        <app-feather-icons [icon]="'log-in'"></app-feather-icons>
        <span>{{ 'Logout' | translate }}</span></a
      >
    </li>
  </ul>
}
