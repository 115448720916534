<div class="cart-box" (click)="openCartOnClick()">
  <app-feather-icons [icon]="'shopping-cart'"></app-feather-icons>
  @if (cartItems$ | async; as cartItems) {
    @if (cartItems.length !== 0) {
      <span class="badge rounded-pill badge-primary fc-white">{{
        this.getTotalItemsLength(cartItems)
      }}</span>
    }
  }
</div>

<div class="cart-contain" [class.open]="openCart">
  <div class="tab-content" id="c-pills-tabContent">
    <div class="cart-header">
      <h5>{{ 'My Cart' | translate }}</h5>
      <svg
        (click)="openCartOnClick()"
        class="icon-close"
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.2463 29.0142C21.9534 29.0142 21.6604 28.9063 21.4292 28.6751C21.2142 28.4575 21.0936 28.1639 21.0936 27.858C21.0936 27.5521 21.2142 27.2585 21.4292 27.0409L29.97 18.5001L21.4292 9.95923C21.2142 9.74164 21.0936 9.44805 21.0936 9.14214C21.0936 8.83623 21.2142 8.54265 21.4292 8.32506C21.8763 7.87798 22.6163 7.87798 23.0634 8.32506L32.4213 17.683C32.8684 18.1301 32.8684 18.8701 32.4213 19.3171L23.0634 28.6751C22.8321 28.9063 22.5392 29.0142 22.2463 29.0142Z"
          fill="#E05F57"
        />
        <path
          d="M31.3421 19.6562H5.39581C4.76373 19.6562 4.23956 19.1321 4.23956 18.5C4.23956 17.8679 4.76373 17.3438 5.39581 17.3438H31.3421C31.9741 17.3438 32.4983 17.8679 32.4983 18.5C32.4983 19.1321 31.9741 19.6562 31.3421 19.6562Z"
          fill="#E05F57"
        />
      </svg>
    </div>
    <div class="cart-body custom-scrollbar">
      <div
        class="tab-pane fade"
        [class.active]="openCart"
        [class.show]="openCart"
        id="c-pills-home"
        role="tabpanel"
        aria-labelledby="c-pills-home-tab"
      >
        <ul class="cart-dropdown">
          @if ((cartItems$ | async).length === 0) {
            <li class="mt-0 cart-card">
              <div class="d-flex position-relative">
                <div class="media-body">
                  <div class="description-wrapper">
                    <span>{{ 'Empty cart' | translate }}</span>
                  </div>
                </div>
              </div>
            </li>
          }
          @for (item of cartItems$ | async; track $index) {
            <li class="mt-0 cart-card">
              <div class="d-flex position-relative">
                <div class="media-body">
                  <div class="image-wrapper">
                    <img
                      class="img-fluid rounded-circle"
                      [src]="
                        item?.images
                          ? item?.images?.[0]
                          : '/assets/images/ecommerce/kit-small.jpg'
                      "
                      alt=""
                    />
                    <span class="badge rounded-pill badge-primary">{{
                      item.quantity
                    }}</span>
                  </div>
                  <div class="description-wrapper">
                    <span>{{ item.title }}</span>
                    <p>
                      {{
                        item.description.length > 33
                          ? item.description.slice(0, 33) + ' ...'
                          : item.description
                      }}
                    </p>
                    <div class="qty-box">
                      <div class="input-group">
                        <span class="input-group-prepend">
                          <button
                            class="btn quantity-left-minus"
                            type="button"
                            data-type="minus"
                            style="color: black"
                            (click)="
                              decreaseQuantity(item); $event.stopPropagation()
                            "
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="feather feather-minus-square font-secondary"
                            >
                              <rect
                                width="20"
                                height="20"
                                rx="2"
                                fill="#E2E5E9"
                              />
                              <path
                                d="M14.2105 10.5789H5.78947C5.35789 10.5789 5 10.2211 5 9.78947C5 9.35789 5.35789 9 5.78947 9H14.2105C14.6421 9 15 9.35789 15 9.78947C15 10.2211 14.6421 10.5789 14.2105 10.5789Z"
                                fill="#647387"
                              />
                            </svg>
                          </button>
                        </span>
                        <input
                          class="form-control input-number"
                          type="text"
                          name="quantity"
                          [value]="item.quantity"
                          (change)="inputQuantity($event, item)"
                          data-original-title=""
                          title=""
                          (keydown)="callCheckLength($event, inputText)"
                          #inputText
                        />
                        <span class="input-group-prepend">
                          <button
                            class="btn quantity-right-plus"
                            type="button"
                            data-type="plus"
                            (click)="
                              increaseQuantity(item); $event.stopPropagation()
                            "
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="feather feather-plus-square"
                            >
                              <rect
                                width="20"
                                height="20"
                                rx="2"
                                fill="#E2E5E9"
                              />
                              <path
                                d="M14.2105 9.21053H10.7895V5.78947C10.7895 5.35789 10.4316 5 10 5C9.56842 5 9.21053 5.35789 9.21053 5.78947V9.21053H5.78947C5.35789 9.21053 5 9.56842 5 10C5 10.4316 5.35789 10.7895 5.78947 10.7895H9.21053V14.2105C9.21053 14.6421 9.56842 15 10 15C10.4316 15 10.7895 14.6421 10.7895 14.2105V10.7895H14.2105C14.6421 10.7895 15 10.4316 15 10C15 9.56842 14.6421 9.21053 14.2105 9.21053Z"
                                fill="#647387"
                              />
                            </svg>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="item-price">
                    <h6 class="text-start pt-4">{{ item.value | currency }}</h6>
                  </div>
                </div>
                <div class="close-circle">
                  <a>
                    <app-feather-icons
                      [icon]="'trash-2'"
                      (click)="
                        removeItemFromCart(item); $event.stopPropagation()
                      "
                    ></app-feather-icons>
                  </a>
                </div>
              </div>
            </li>
          }
        </ul>
        <div class="total">
          <div>
            <h6 class="mb-2 mt-0 text-muted">
              {{ 'Order Total' | translate }}
              <span class="f-right">{{
                total$ | async | currency: 'BRL'
              }}</span>
            </h6>
          </div>
        </div>
        <div class="buttons">
          <a class="btn w-100 btn-blue view-cart" (click)="goToCheckout()">
            {{ 'Checkout' | translate }}
          </a>
          <div class="row">
            <div class="col-6 btn-left">
              <a
                class="btn w-100 btn-grey view-cart"
                (click)="removeItemsFromCart()"
              >
                <app-feather-icons [icon]="'trash-2'"></app-feather-icons>
                {{ 'Abandon Cart' | translate }}
              </a>
            </div>
            <div class="col-6 btn-right">
              <a
                class="btn w-100 btn-black view-cart"
                (click)="openCartOnClick()"
              >
                {{ 'Continue Shopping' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
