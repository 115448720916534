import { HttpInterceptorFn } from '@angular/common/http'
import { UserMetaState } from '../../store/user/user.meta.state'
import { InterceptorSkip } from '../../interceptors/token.interceptor'
import { inject } from '@angular/core'
import { Store } from '@ngxs/store'

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store)

  if (req.headers && req.headers.has(InterceptorSkip)) {
    const headers = req.headers.delete(InterceptorSkip)
    return next(req.clone({ headers }))
  }
  const token = store.selectSnapshot(UserMetaState.getUserToken)

  const authRequest = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  })

  return next(authRequest)
}
