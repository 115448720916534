import {
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog'
import { Image } from '@ks89/angular-modal-gallery'
import { firstValueFrom } from 'rxjs'
import { FormComponent } from 'src/app/shared/components/form/form.component'
import { LoaderModalComponent } from 'src/app/shared/components/loader-modal/loader-modal.component'
import { UploadPhotoComponent } from 'src/app/shared/components/upload-photo/upload-photo.component'
import { LocalStorageService } from 'src/app/shared/services/local-storage.service'
import { FormHelper } from 'src/helpers/form.helper'
import { Utils } from 'src/helpers/utils.helper'
import { IQuestion } from 'src/interfaces/question/question.interface'
import { User } from 'src/interfaces/user/user.interface'
import { UserProfileQuestions } from 'src/questions/user-profile.question'
import { UserService } from '../../shared/services/user.service'
import { MatButtonModule } from '@angular/material/button'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    UploadPhotoComponent,
    FormComponent,
  ],
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
})
export class CompanyProfileComponent implements OnInit {
  private utils = inject(Utils)
  private userProfileQuestions = inject(UserProfileQuestions)
  private formHelper = inject(FormHelper)
  private dialog = inject(MatDialog)
  private userService = inject(UserService)
  private destroyRef = inject(DestroyRef)
  public storage = inject(LocalStorageService)

  id: number
  user: User
  questions: IQuestion[] = []
  image = []
  isLoading = false
  mixLayout: string
  public imagesRect: Image[] = this.getPlaceholderImages()

  @ViewChildren(FormComponent) formComponent!: QueryList<FormComponent>
  uploadPhotoComponent!: QueryList<UploadPhotoComponent>

  constructor(@Inject(MAT_DIALOG_DATA) public userId: string) {}

  ngOnInit(): void {
    this.initializeQuestions()
    this.mixLayout = this.storage.getItem('mixLayout')
  }

  getDataInfo(id: string, loader: MatDialogRef<LoaderModalComponent>): void {
    this.userService.getById(id).subscribe({
      next: (response) => {
        const parsedData = this.parseDataFromApi(response)
        this.getImage(response.image)
        setTimeout(() => {
          this.formHelper.patchAllFormFields(
            this.formComponent,
            parsedData as any,
          )
          this.fixMaskValidation()
          loader.close()
        }, 1000)
      },
      error: (error) => {
        console.log(error)
        loader.close()
        this.utils
          .showErrorMessage('Erro ao carregar informações do usuário')
          .then(() => {
            this.onCancel()
          })
      },
    })
  }

  fixMaskValidation(): void {
    this.formHelper.setFieldAsValid(this.formComponent, 'userProfile', 'phone')
    this.formHelper.setFieldAsValid(
      this.formComponent,
      'userProfile',
      'document',
    )
    this.formHelper.setFieldAsValid(
      this.formComponent,
      'userProfile',
      'zipCode',
    )
  }

  initializeQuestions(): void {
    const loader = this.dialog.open(LoaderModalComponent, {
      width: '500px',
      height: '300px',
      panelClass: 'main-modal',
      disableClose: true,
      data: 'Aguarde enquanto carregando algumas informações...',
    })
    this.questions = this.userProfileQuestions.questions
    this.questions[0].isVisible = false
    this.questions[0].required = false
    this.questions[1].isVisible = true
    this.questions[1].required = true
    setTimeout(() => {
      this.getDataInfo(this.userId, loader)
      const localizationForm = this.formComponent.filter(
        (form) => form.key === 'userProfile',
      )[0].form
      this.watchFormChanges(localizationForm)
    }, 1000)
  }

  watchFormChanges(form: any): void {
    setTimeout(() => {
      form.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((value) => {
          const cep = value.zipCode
          if (cep?.length === 9 && cep !== '') {
            this.getAddressByCep(cep)
          }
        })
    }, 1000)
  }

  onCancel() {
    this.dialog.closeAll()
  }

  async getAddressByCep(cep: string): Promise<void> {
    const normalizedCep = this.utils.normalizeCep(cep)
    await firstValueFrom(this.utils.getAddressByCep(normalizedCep)).then(
      (address) => {
        const cepValuesReferences: any = {
          zipCode: address.cep,
          street: address.logradouro,
          neighborhood: address.bairro,
          city: address.localidade,
          state: address.uf,
          country: 'Brasil',
        }
        this.userProfileQuestions.questions
          .map((question) => question.key)
          .forEach((key: string) => {
            const matchedReference = cepValuesReferences[key]
            if (matchedReference) {
              this.formHelper.patchFieldValue(
                this.formComponent,
                'userProfile',
                key,
                matchedReference,
              )
            }
          })
      },
    )
  }

  save(): void {
    const notFilledRequiredFields = this.formHelper.getNotFilledRequiredFields(
      this.formComponent,
    )
    if (notFilledRequiredFields.length > 0) {
      this.handleNotFilledRequiredFields(notFilledRequiredFields)
      return
    }
    const isIdentificationValid = this.formHelper.validateIdentification(
      this.formComponent,
      'userProfile',
      'document',
    )
    if (!isIdentificationValid) {
      this.handleNotValidIdentification()
      return
    }
    const loader = this.dialog.open(LoaderModalComponent, {
      width: '500px',
      height: '300px',
      panelClass: 'main-modal',
      disableClose: true,
      data: 'Aguarde enquanto carregando algumas informações...',
    })
    const data = this.formHelper.getAllFormValues(this.formComponent)
    setTimeout(() => {
      loader.close()
      this.utils
        .showSuccessMessage('Sucesso', 'Perfil editado com sucesso.')
        .then(() => {
          this.onCancel()
        })
    }, 1000)
  }

  getImage(image: string): void {
    if (image) {
      this.isLoading = true
      const parsedImage = image
      this.image = [parsedImage]
    }
  }

  onUploadedImagesChange(event: any) {
    this.isLoading = true
    const images = this.getMultipleCarouselImages(event.images)
    this.imagesRect = [...images]

    this.removePlaceholderImages()
    if (images.length === 0) {
      const placeholderImages = this.getPlaceholderImages()
      this.imagesRect = [...placeholderImages]
    }
    setTimeout(() => {
      this.isLoading = false
    }, 1000)
  }

  getMultipleCarouselImages(images: any[]) {
    return images.map((image, index) => {
      return new Image(index + 1, { img: image }, { img: image })
    })
  }

  getPlaceholderImages() {
    return [
      new Image(
        0,
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
      ),
      new Image(
        1,
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
      ),
      new Image(
        2,
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
      ),
      new Image(
        3,
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
      ),
    ]
  }

  removePlaceholderImages() {
    const placeholderImages = this.imagesRect.filter(
      (image) =>
        image.modal.img === 'assets/images/ecommerce/placeholder-image.jpg',
    )
    placeholderImages.forEach((image) => {
      this.imagesRect.splice(image.id, 1)
    })
  }

  handleNotFilledRequiredFields(notFilledRequiredFields: string[]): void {
    const notFilledRequiredFieldsTitles = notFilledRequiredFields.join(', ')
    this.utils.showWarningMessage(
      'Preencha corretamente os seguintes campos obrigatórios',
      notFilledRequiredFieldsTitles,
    )
  }

  handleNotValidIdentification(): void {
    this.utils.showWarningMessage(
      'CPF inválido',
      'O CPF informado não é válido',
    )
  }

  private parseData(data: any): any {
    const formData = data.userProfile
    const parsedIdentification = formData?.document
      ? this.utils.normalizeCpf(formData?.document)
      : ''
    const parsedZipCode = formData?.zipCode
      ? this.utils.normalizeCep(formData?.zipCode)
      : ''
    const parsedPhoneNumber = this.utils.normalizePhoneNumber(formData.phone)
    const formParams = {
      formComponent: this.formComponent,
      formKey: 'userProfile',
    }
    const disabledInformation = {
      city: this.formHelper.getField(
        formParams.formComponent,
        formParams.formKey,
        'city',
      )?.value,
      state: this.formHelper.getField(
        formParams.formComponent,
        formParams.formKey,
        'state',
      )?.value,
      country: this.formHelper.getField(
        formParams.formComponent,
        formParams.formKey,
        'country',
      )?.value,
    }

    const image = this.uploadPhotoComponent.last.uploadedImages[0]

    return {
      name: formData.name,
      email: formData.email,
      document: parsedIdentification,
      phone: parsedPhoneNumber,
      address: {
        zipCode: parsedZipCode,
        street: formData.street,
        number: formData.number.toString(),
        neighborhood: formData.neighborhood,
        city: disabledInformation.city,
        state: disabledInformation.state,
        country: disabledInformation.country,
        complement: formData.complement,
      },
      image: image,
    }
  }

  /**
   * Parse data from API to match with form fields
   * @param data
   * @returns
   */
  private parseDataFromApi(data: any) {
    const parsedPhoneNumber = this.utils.parsePhoneNumber(data?.phone)
    const parsedIdentification = this.utils
      .formatIdentification(data?.document)
      .replace(/\D/g, '')
    delete data?.password
    return {
      ...data,
      document: parsedIdentification,
      phone: parsedPhoneNumber,
      street: data?.address?.street,
      neighborhood: data?.address?.neighborhood,
      city: data?.address?.city,
      state: data?.address?.state,
      country: data?.address?.country,
      zipCode: data?.address?.zipCode,
      number: data?.address?.number,
      complement: data?.address?.complement,
    }
  }
}
