<form class="form-inline search-full" [class.open]="showSearch">
  <div class="form-group align-items-center w-100 mb-0">
    <app-feather-icons
      class="d-flex align-items-center search-icon"
      [icon]="'search'"
    ></app-feather-icons>
    <input
      [id]="'search'"
      class="form-control-plaintext"
      [(ngModel)]="text"
      [ngModelOptions]="{ standalone: true }"
      (keyup)="searchTerm(text)"
      type="text"
      autocomplete="off"
      [placeholder]="
        'Search for PO (Order Number, Invoice Name, Invoice Document or BYD Number)'
          | translate
      "
    />
    <app-feather-icons
      class="d-flex align-items-center close-icon"
      [icon]="'x'"
      (click)="handleClickClose()"
    ></app-feather-icons>
    @if (proposals.length > 0) {
      <div class="Typeahead-menu" [class.is-open]="true" id="search-outer">
        <app-request-list
          [proposals]="proposals"
          [isHeaderSource]="true"
          (selectedProposals)="handleSelectedProposals($event)"
        ></app-request-list>
      </div>
    }
    @if (menuItems?.length) {
      <div class="Typeahead-menu" [class.is-open]="searchResult"></div>
    }
    <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
      <div class="tt-dataset tt-dataset-0">
        <div class="EmptyMessage">Opps!! There are no result found.</div>
      </div>
    </div>
  </div>
</form>
