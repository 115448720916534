<div
  class="page-header"
  [class.close_icon]="navServices.collapseSidebar"
  [class.hover_icon]="navServices.hoverSidebar"
>
  @if (isStaging) {
    <div class="staging-bar">
      {{ 'This is a trial version.' | translate }}
    </div>
  }
  <app-search
    [showSearch]="showSearch"
    (sendShowSearch)="reciveShowSearch($event)"
  ></app-search>
  <div [id]="'vanishingPoint'" class="header-wrapper row m-0">
    <div class="col-xl-auto col-md-auto col-auto fill-width">
      <div class="row">
        <div
          class="header-logo-wrapper col-xl-auto col-md-auto col-auto d-lg-none px-0"
        >
          <div class="logo-wrapper">
            <a>
              <img
                class="img-fluid for-light"
                src="/assets/images/logo/logo-black.png"
                alt=""
              />
              <img
                class="img-fluid for-dark"
                src="/assets/images/logo/logo.png"
                alt=""
              />
            </a>
          </div>
          <div
            class="toggle-sidebar"
            id="icon-toggle-sidebar"
            (click)="sidebarToggle()"
          >
            <app-feather-icons
              [icon]="'align-center'"
              class="status_toggle middle"
            ></app-feather-icons>
          </div>
        </div>
        <div
          class="d-flex nav-left pull-left col-xl-auto col-md-auto col-auto p-0"
        >
          <ul class="nav-menus fill-width">
            <li class="fill-width">
              <app-weather [weather]="weather"></app-weather>
            </li>
            <li class="bar"></li>
            <li class="nav-padding nav-message">
              <div class="swiper-container">
                <swiper [config]="config" class="swiper-container">
                  @for (notification of notifications; track $index) {
                    <ng-template swiperSlide>
                      <a [routerLink]="notification.path">
                        <span>{{ notification.notification | translate }}</span>
                      </a>
                    </ng-template>
                  }
                </swiper>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="nav-padding nav-options d-flex nav-right col-xl-auto col-md-auto col-auto pull-right right-header"
    >
      <ul class="nav-menus">
        <li class="py-0 search">
          <app-feather-icons
            class="d-flex align-items-center"
            [icon]="'search'"
            (click)="showSearch = !showSearch"
          ></app-feather-icons>
        </li>
        <li class="onhover-dropdown">
          <app-customizer></app-customizer>
        </li>
        <li class="maximize">
          <a
            class="text-dark"
            href="javascript:void(0)"
            (click)="toggleFullScreen()"
          >
            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li>
        <li class="cart-nav onhover-dropdown">
          <app-cart></app-cart>
        </li>
        <li class="sidebar-main-title profile-nav py-0 px-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
