import { Component, Input, OnInit } from '@angular/core'
import { Weather } from 'src/interfaces/weather/weather.interface'
import { DatePipe, TitleCasePipe } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  standalone: true,
  imports: [DatePipe, TranslateModule, TitleCasePipe],
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent implements OnInit {
  @Input() weather: Weather[]

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      console.log(this.weather)
    }, 1000)
  }
}
