import { Store } from '@ngxs/store'
import { Component, inject, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SetLanguageAction } from 'src/store/layout/layout.meta.actions'
import { LayoutMetaState } from 'src/store/layout/layout.meta.state'

@Component({
  standalone: true,
  imports: [],
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  private translate = inject(TranslateService)
  private store = inject(Store)

  public language: boolean = false

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us',
    },
    {
      language: 'Português',
      code: 'pt',
      type: 'BR',
      icon: 'br',
    },
  ]

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us',
  }

  ngOnInit() {
    const language = this.store.selectSnapshot(LayoutMetaState.getLanguage)
    if (language) {
      this.selectedLanguage = this.languages.find(
        (lang) => lang.code === language,
      )
    }
  }

  changeLanguage(lang) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang
    this.store.dispatch(new SetLanguageAction(lang.code))
  }
}
