import {
  Component,
  DestroyRef,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog'
import { Image } from '@ks89/angular-modal-gallery'
import { Store } from '@ngxs/store'
import { firstValueFrom } from 'rxjs'
import { FormComponent } from 'src/app/shared/components/form/form.component'
import { LoaderModalComponent } from 'src/app/shared/components/loader-modal/loader-modal.component'
import { UploadPhotoComponent } from 'src/app/shared/components/upload-photo/upload-photo.component'
import { LocalStorageService } from 'src/app/shared/services/local-storage.service'
import { environment } from 'src/environments/environment'
import { FormHelper } from 'src/helpers/form.helper'
import { Utils } from 'src/helpers/utils.helper'
import { IFiles } from 'src/interfaces/files/files.interface'
import { IQuestion } from 'src/interfaces/question/question.interface'
import { User } from 'src/interfaces/user/user.interface'
import { RegisterQuestions } from 'src/questions/register.questions'
import { UserProfileQuestions } from 'src/questions/user-profile.question'
import { UserMetaState } from 'src/store/user/user.meta.state'
import { UserService } from '../../shared/services/user.service'
import { SetCurrentUserAction } from '../../../store/user/user.meta.actions'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { NgxDropzoneModule } from 'ngx-dropzone'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    NgxDropzoneModule,
    TranslateModule,
    UploadPhotoComponent,
    FormComponent,
  ],
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private utils = inject(Utils)
  private userProfileQuestions = inject(UserProfileQuestions)
  private registerQuestions = inject(RegisterQuestions)
  private formHelper = inject(FormHelper)
  private dialog = inject(MatDialog)
  private userService = inject(UserService)
  private store = inject(Store)
  private destroyRef = inject(DestroyRef)
  public storage = inject(LocalStorageService)

  id: number
  user: User
  questions: IQuestion[] = []
  socialMedia: IQuestion[] = []

  image = []
  staticImage = []
  staticFiles = []

  selectedIdentificationCard: IFiles[] = []
  selectedSocialContract: IFiles[] = []
  selectedCreaNumber: IFiles[] = []

  isLoading = false
  mixLayout: string
  public imagesRect: Image[] = this.getPlaceholderImages()

  @ViewChildren(FormComponent) formComponent!: QueryList<FormComponent>
  @ViewChildren(UploadPhotoComponent)
  uploadPhotoComponent!: QueryList<UploadPhotoComponent>

  constructor(@Inject(MAT_DIALOG_DATA) public userId: string) {}

  ngOnInit(): void {
    this.initializeQuestions()
    this.mixLayout = this.storage.getItem('mixLayout')
  }

  ngOnDestroy(): void {
    this.formHelper.resetAllForms(this.formComponent)
    this.socialMedia = [...this.registerQuestions.imutableSocialMedia]
  }

  getDataInfo(id: string, loader: MatDialogRef<LoaderModalComponent>): void {
    this.userService.getById(id).subscribe({
      next: (response) => {
        const parsedData = this.parseDataFromApi(response)
        const files = response.files.length > 0 ? response.files : []
        this.getImage(response.files)
        const parsedFiles = this.parseFiles(files)
        this.staticFiles = [...files]
        const filesToGet = parsedFiles ? [...parsedFiles] : []
        this.getFiles(filesToGet)
        setTimeout(() => {
          this.formHelper.patchAllFormFields(this.formComponent, parsedData)
          if (response?.registerInfo?.socialMedia) {
            this.patchSocialMedias(response.registerInfo.socialMedia)
          }
          this.fixMaskValidation()
          loader.close()
        }, 1000)
      },
      error: (error) => {
        console.log(error)
        loader.close()
        this.utils
          .showErrorMessage('Erro ao carregar informações do usuário')
          .then(() => {
            this.onCancel()
          })
      },
    })
  }

  fixMaskValidation(): void {
    this.formHelper.setFieldAsValid(this.formComponent, 'userProfile', 'phone')
    this.formHelper.setFieldAsValid(
      this.formComponent,
      'userProfile',
      'document',
    )
    this.formHelper.setFieldAsValid(
      this.formComponent,
      'userProfile',
      'zipCode',
    )
  }

  initializeQuestions(): void {
    const loader = this.dialog.open(LoaderModalComponent, {
      width: '500px',
      height: '300px',
      panelClass: 'main-modal',
      disableClose: true,
      data: 'Aguarde enquanto carregando algumas informações...',
    })
    this.questions = this.userProfileQuestions.questions
    this.questions[0].isVisible = true
    this.questions[0].required = true
    this.questions[1].isVisible = false
    this.questions[1].required = false
    this.socialMedia = [...this.registerQuestions.socialMedia]
    setTimeout(() => {
      this.getDataInfo(this.userId, loader)
      const localizationForm = this.formComponent.filter(
        (form) => form.key === 'userProfile',
      )[0].form
      this.watchFormChanges(localizationForm)
    }, 1000)
  }

  watchFormChanges(form: any): void {
    setTimeout(() => {
      form.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((value) => {
          const cep = value.zipCode
          if (cep?.length === 9 && cep !== '') {
            this.getAddressByCep(cep)
          }
        })
    }, 1000)
  }

  onCancel() {
    this.dialog.closeAll()
  }

  async getAddressByCep(cep: string): Promise<void> {
    const normalizedCep = this.utils.normalizeCep(cep)
    await firstValueFrom(this.utils.getAddressByCep(normalizedCep)).then(
      (address) => {
        const cepValuesReferences: any = {
          zipCode: address.cep,
          street: address.logradouro,
          neighborhood: address.bairro,
          city: address.localidade,
          state: address.uf,
          country: 'Brasil',
        }
        this.userProfileQuestions.questions
          .map((question) => question.key)
          .forEach((key: string) => {
            const matchedReference = cepValuesReferences[key]
            if (matchedReference) {
              this.formHelper.patchFieldValue(
                this.formComponent,
                'userProfile',
                key,
                matchedReference,
              )
            }
          })
      },
    )
  }

  async save(): Promise<void> {
    const notFilledRequiredFields = this.formHelper.getNotFilledRequiredFields(
      this.formComponent,
    )
    if (notFilledRequiredFields.length > 0) {
      this.handleNotFilledRequiredFields(notFilledRequiredFields)
      return
    }
    const isIdentificationValid = this.formHelper.validateIdentification(
      this.formComponent,
      'userProfile',
      'document',
    )
    if (!isIdentificationValid) {
      this.handleNotValidIdentification()
      return
    }
    const loader = this.dialog.open(LoaderModalComponent, {
      width: '500px',
      height: '300px',
      panelClass: 'main-modal',
      disableClose: true,
      data: 'Aguarde enquanto carregando algumas informações...',
    })
    const data = this.formHelper.getAllFormValues(this.formComponent)
    const parsedData = await this.parseData(data)
    if (parsedData.password === '') {
      delete parsedData.password
    }
    this.userService.updateUserProfile(parsedData).subscribe({
      next: (response) => {
        const userOutdatedData = this.store.selectSnapshot(
          UserMetaState.getUser,
        )
        const userUpdatedData = response.user
        const payload = {
          ...userOutdatedData,
          ...userUpdatedData,
        }
        this.store.dispatch(new SetCurrentUserAction(payload))
        this.checkFiles(response.user.id, loader)
      },
      error: (error) => {
        console.log(error)
        loader.close()
        const errorMessage = this.utils.getErrorMessage(error)
        this.utils.showErrorMessage(
          'Erro',
          errorMessage || 'Ocorreu um erro ao editar o perfil.',
        )
      },
    })
  }

  onDeleteImage(deletedImage: IFiles[]) {
    const loader = this.dialog.open(LoaderModalComponent, {
      width: '500px',
      height: '300px',
      panelClass: 'main-modal',
      disableClose: true,
      data: 'Aguarde enquanto carregando algumas informações...',
    })
    if (this.staticImage.length === 0) {
      loader.close()
      return
    }
    const user = this.store.selectSnapshot(UserMetaState.getUser)
    this.userService.deleteFiles(this.staticImage, user.id).subscribe({
      next: (res) => {
        this.image = []
        this.staticImage = []
        loader.close()
      },
      error: (err) => {
        console.log(err)
        const errorMessage = this.utils.getErrorMessage(err)
        this.utils.showErrorMessage('Erro ao remover os arquivos', errorMessage)
      },
    })
  }

  getImage(files: any[]): void {
    const image = files.filter(
      (file) => file.metadata.customMetadata.attachmentType === 'IMAGE',
    )
    if (image.length > 0) {
      this.staticImage = image
      this.isLoading = true
      const parsedImage = {
        url: environment.api + '/' + image[0].downloadUrl,
      }
      this.image = [parsedImage]
    }
  }

  onUploadedImagesChange(uploadedImages: IFiles[]) {
    this.image = uploadedImages
  }

  getMultipleCarouselImages(images: any[]) {
    return images.map((image, index) => {
      return new Image(index + 1, { img: image }, { img: image })
    })
  }

  getPlaceholderImages() {
    return [
      new Image(
        0,
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
      ),
      new Image(
        1,
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
      ),
      new Image(
        2,
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
      ),
      new Image(
        3,
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
        { img: 'assets/images/ecommerce/placeholder-image.jpg' },
      ),
    ]
  }

  removePlaceholderImages() {
    const placeholderImages = this.imagesRect.filter(
      (image) =>
        image.modal.img === 'assets/images/ecommerce/placeholder-image.jpg',
    )
    placeholderImages.forEach((image) => {
      this.imagesRect.splice(image.id, 1)
    })
  }

  handleNotFilledRequiredFields(notFilledRequiredFields: string[]): void {
    const notFilledRequiredFieldsTitles = notFilledRequiredFields.join(', ')
    this.utils.showWarningMessage(
      'Preencha corretamente os seguintes campos obrigatórios',
      notFilledRequiredFieldsTitles,
    )
  }

  handleNotValidIdentification(): void {
    this.utils.showWarningMessage(
      'CPF inválido',
      'O CPF informado não é válido',
    )
  }

  private async parseData(data: any): Promise<any> {
    const formData = data.userProfile
    const socialMedia = this.parseSocialMedias(data.socialMedia)
    const parsedIdentification = formData?.document
      ? this.utils.normalizeCpf(formData?.document)
      : ''
    const parsedZipCode = formData?.zipCode
      ? this.utils.normalizeCep(formData?.zipCode)
      : ''
    const parsedPhoneNumber = this.utils.normalizePhoneNumber(formData.phone)
    const formParams = {
      formComponent: this.formComponent,
      formKey: 'userProfile',
    }
    const disabledInformation = {
      city: this.formHelper.getField(
        formParams.formComponent,
        formParams.formKey,
        'city',
      )?.value,
      state: this.formHelper.getField(
        formParams.formComponent,
        formParams.formKey,
        'state',
      )?.value,
      country: this.formHelper.getField(
        formParams.formComponent,
        formParams.formKey,
        'country',
      )?.value,
    }

    return {
      displayName: formData.name,
      email: formData.email,
      password: formData.password,
      document: parsedIdentification,
      phone: parsedPhoneNumber,
      address: {
        zipCode: parsedZipCode,
        street: formData.street,
        number: formData.number.toString(),
        neighborhood: formData.neighborhood,
        city: disabledInformation.city,
        state: disabledInformation.state,
        country: disabledInformation.country,
        complement: formData.complement,
      },
      registerInfo: {
        installedProjects: null,
        stateRegistration: formData?.stateRegistration,
        website: formData?.website,
        socialMedia: socialMedia,
      },
    }
  }

  /**
   * Parse data from API to match with form fields
   * @param data
   * @returns
   */
  private parseDataFromApi(data: any) {
    const parsedPhoneNumber = this.utils.parsePhoneNumber(data?.phone)
    const parsedIdentification = this.utils
      .formatIdentification(data?.document)
      .replace(/\D/g, '')
    delete data?.password
    return {
      ...data,
      name: data?.displayName,
      document: parsedIdentification,
      phone: parsedPhoneNumber,
      street: data?.address?.street,
      neighborhood: data?.address?.neighborhood,
      city: data?.address?.city,
      state: data?.address?.state,
      country: data?.address?.country,
      zipCode: data?.address?.zipCode,
      number: data?.address?.number,
      complement: data?.address?.complement,
      stateRegistration: data?.registerInfo?.stateRegistration,
      website: data?.registerInfo?.website,
    }
  }

  uploadFiles(
    userId: string,
    files: IFiles[],
    loader: MatDialogRef<LoaderModalComponent>,
  ): void {
    this.userService.uploadFiles(files, userId).subscribe({
      next: (res) => {
        loader.close()
        this.utils
          .showSuccessMessage('Sucesso', 'Usuário editado com sucesso.')
          .then(() => {
            this.onCancel()
          })
      },
      error: (err) => {
        console.log(err)
        const errorMessage = this.utils.getErrorMessage(err)
        this.utils.showErrorMessage(
          'Erro ao fazer upload de arquivos',
          errorMessage,
        )
      },
    })
  }

  parseFiles(files: any): IFiles[] {
    return files.map((file) => {
      return {
        id: file?.metadata?.customMetadata?.id,
        url: environment.api + '/' + file?.downloadUrl,
        unsupported: false,
        name: file?.itemName,
        author: file?.metadata?.customMetadata?.ownerId,
        attachmentType: file?.metadata?.customMetadata?.attachmentType,
      }
    })
  }

  private getFiles(files: IFiles[]) {
    this.selectedIdentificationCard = []
    this.selectedSocialContract = []
    this.selectedCreaNumber = []
    files.forEach((file) => {
      if (file.attachmentType === 'IDENTIFICATION_CARD') {
        this.selectedIdentificationCard = [file]
      }
      if (file.attachmentType === 'SOCIAL_CONTRACT') {
        this.selectedSocialContract = [file]
      }
      if (file.attachmentType === 'CREA_NUMBER') {
        this.selectedCreaNumber = [file]
      }
    })
  }

  checkFiles(userId: string, loader: MatDialogRef<LoaderModalComponent>) {
    const files = []
    const selectedFiles = [
      ...this.image,
      ...this.selectedIdentificationCard,
      ...this.selectedSocialContract,
      ...this.selectedCreaNumber,
    ]
    selectedFiles.forEach((file) => {
      if (file.file) {
        files.push(file)
      }
    })
    if (files.length > 0) {
      this.uploadFiles(userId, files, loader)
    } else {
      loader.close()
      this.utils
        .showSuccessMessage('Sucesso', 'Usuário editado com sucesso.')
        .then(() => {
          this.onCancel()
        })
    }
  }

  async onSelect(event: any, attachmentType: string) {
    if (event.rejectedFiles.length > 0) {
      this.utils.showErrorMessage(
        'Tipo de arquivo não suportado',
        'Tipos suportados: .pdf',
      )
      return
    }
    const author = this.store.selectSnapshot(UserMetaState.getUser)
    const files = event.addedFiles
    for (const file of files) {
      const url = URL.createObjectURL(file)
      const sanitizedFile = this.utils.sanitizeFile(url) as any
      const unsupported = this.isFileUnsupported(file)
      const randomStringId = this.utils.generateRandomString(10)
      const selectedFile = {
        id: randomStringId,
        file,
        type: file.type,
        name: file.name,
        url: sanitizedFile,
        unsupported,
        author,
        attachmentType,
      }
      if (attachmentType === 'IDENTIFICATION_CARD') {
        this.selectedIdentificationCard = [selectedFile]
      }
      if (attachmentType === 'SOCIAL_CONTRACT') {
        this.selectedSocialContract = [selectedFile]
      }
      if (attachmentType === 'CREA_NUMBER') {
        this.selectedCreaNumber = [selectedFile]
      }
    }
  }

  isFileUnsupported(file: any) {
    const availableFileTypes = ['pdf', 'image']
    const splittedFileTypes = file.type.split('/')
    return (
      !availableFileTypes.includes(splittedFileTypes[0]) &&
      !availableFileTypes.includes(splittedFileTypes[1])
    )
  }

  removeFile(deletedFile: IFiles[], attachmentType: string) {
    const loader = this.dialog.open(LoaderModalComponent, {
      width: '500px',
      height: '300px',
      panelClass: 'main-modal',
      disableClose: true,
      data: 'Aguarde enquanto carregando algumas informações...',
    })
    const user = this.store.selectSnapshot(UserMetaState.getUser)
    const foundItem = this.staticFiles.filter((item) => {
      if (
        item?.metadata?.customMetadata?.id === deletedFile[0].id &&
        item?.itemName === deletedFile[0].name
      ) {
        return item
      }
    })
    if (foundItem.length === 0) {
      loader.close()
      if (attachmentType === 'IDENTIFICATION_CARD') {
        this.selectedIdentificationCard = []
      }
      if (attachmentType === 'SOCIAL_CONTRACT') {
        this.selectedSocialContract = []
      }
      if (attachmentType === 'CREA_NUMBER') {
        this.selectedCreaNumber = []
      }
      return
    }
    this.deleteFiles(foundItem, user.id, loader)
  }

  deleteFiles(
    foundItem: any,
    userId: string,
    loader: MatDialogRef<LoaderModalComponent>,
  ) {
    this.userService.deleteFiles(foundItem, userId).subscribe({
      next: (res) => {
        const files = res?.files.length > 0 ? res?.files : []
        const parsedFiles = this.parseFiles(files)
        this.staticFiles = [...files]
        const filesToGet = parsedFiles ? [...parsedFiles] : []
        this.getFiles(filesToGet)
        loader.close()
      },
      error: (err) => {
        console.log(err)
        const errorMessage = this.utils.getErrorMessage(err)
        this.utils.showErrorMessage('Erro ao remover os arquivos', errorMessage)
        loader.close()
      },
    })
  }

  downloadFile(fileUrl: string): void {
    window.open(`${fileUrl}`, '_blank')
  }

  addRow(): void {
    const keys = ['name', 'link']
    const customProperty = {
      customIcon: {
        icon: 'close',
        class: 'close-icon',
      },
    }
    this.formHelper.addNewRow(
      this.formComponent,
      'socialMedia',
      keys,
      customProperty,
    )
  }

  onCloseIconClicked(event: IQuestion): void {
    const keys = ['name', 'link']
    const rowNumber = Number(event.key.split('-')[1])
    this.formHelper.removeRow(
      this.formComponent,
      'socialMedia',
      keys,
      rowNumber,
    )
  }
  private parseSocialMedias(socialMedias: any): any {
    const parsedSocialMedias = []
    Object.keys(socialMedias).forEach((key) => {
      if (key.includes('-')) {
        if (Number(socialMedias[key]) === 0) {
          return
        }
        const keySplit = key.split('-')
        const keyName = keySplit[0]
        const keyNumber = keySplit[1]
        if (keyName === 'name') {
          const parsedCharacteristic = {
            name: socialMedias[key],
            link: socialMedias['link-' + keyNumber],
          }
          parsedSocialMedias.push(parsedCharacteristic)
        }
      }
    })
    return parsedSocialMedias.length === 0 ? null : parsedSocialMedias
  }

  patchSocialMedias(socialMedias: any[]): void {
    if (socialMedias.length === 0) return
    const keys = ['name', 'link']
    const customProperty = {
      customIcon: {
        icon: 'close',
        class: 'close-icon',
      },
    }
    this.formHelper.patchRows(
      this.formComponent,
      'socialMedia',
      socialMedias,
      keys,
      customProperty,
    )
  }
}
