import { IQuestion } from './../interfaces/question/question.interface'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class LayoutQuestions {
  LanguageQuestions: IQuestion[] = [
    {
      formKey: 'languageTab',
      key: 'languageType',
      title: '',
      required: false,
      value: 'en',
      isDisabled: false,
      isVisible: true,
      response: {
        value: '',
      },
      type: 'select',
      class: 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12',
      choices: [
        {
          value: 'en',
          title: 'English',
        },
        {
          value: 'pt',
          title: 'Português',
        },
      ],
    },
  ]
  FontQuestions: IQuestion[] = [
    {
      key: 'fontType',
      formKey: 'fontTab',
      title: '',
      value: 'Roboto',
      required: false,
      isVisible: true,
      isDisabled: false,
      response: {
        value: '',
      },
      class: 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12',
      type: 'select',
      choices: [
        {
          value: 'Roboto',
          title: 'Default',
        },
        {
          value: 'Arial',
          title: 'Arial',
        },
        {
          value: 'Dengxian',
          title: 'Dengxian',
        },
        {
          value: 'Calibri',
          title: 'Calibri',
        },
        {
          value: 'Arial Nova Light',
          title: 'Arial Nova Light',
        },
        {
          value: 'Century Gothic',
          title: 'Century Gothic',
        },
        {
          value: 'Microsoft Yahei',
          title: 'Microsoft Yahei',
        },
      ],
    },
  ]
}
